export default {
  state: {
    searchKey: 0,
    searchText: '',
  },
  getters: {
  },
  mutations: {
    SET_SEARCH_KEY(state, data) {
      // 变更状态
      state.searchKey++;
    },
    SET_SEARCH_TEXT(state, data) {
      // 变更状态
      state.searchText = data;
    }
  },
  actions: {
    setSearchText({ commit }, data) {
      commit('SET_SEARCH_KEY');
      commit('SET_SEARCH_TEXT', data);
    },
  },
  namespaced: true,
}
