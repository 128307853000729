import $http from './request' // 引入封装的文件 - 请求接口方法
import api from '../api/api.js'
import { Message } from 'element-ui';

let msgTarget;
export default {

  //直接回到页面顶部
  toPageTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },

  //根据登录状态获取token uid
  getToken() {
    var isRemember = localStorage.getItem('isRemember') || false;//是否记住登录状态
    var xndx_token = '';
    var xndx_uid = '';
    if (isRemember) {
      xndx_token = localStorage.getItem('xndx_token') || '';
      xndx_uid = localStorage.getItem('xndx_uid') || '';

      if (this.isEmpty(xndx_token)) {
        xndx_token = sessionStorage.getItem('xndx_token') || '';
      }
      if (this.isEmpty(xndx_uid)) {
        xndx_uid = sessionStorage.getItem('xndx_uid') || '';
      }
    } else {
      xndx_token = sessionStorage.getItem('xndx_token') || '';
      xndx_uid = sessionStorage.getItem('xndx_uid') || '';
    }
    return { token: xndx_token, uid: xndx_uid };
  },


  shareConfig(wx, share_href, data, share_icon) {


    // share_href = share_href.replace('/#/', '/?#/');

    wx.ready(function () {

      //分享到朋友圈
      wx.onMenuShareTimeline({
        title: data.title, // 分享时的标题
        link: share_href, // 分享时的链接
        imgUrl: share_icon, // 分享时的图标
        desc: data.type_name, // 分享时的简介
        success: function (res) {
          console.log("onMenuShareTimeline-success", res)
        },
        cancel: function (error) {
          console.log("onMenuShareTimeline-error", error)
        }
      });


      //分享给朋友
      wx.onMenuShareAppMessage({
        title: data.title,// 分享时的标题
        link: share_href, // 分享时的链接
        imgUrl: share_icon, // 分享时的图标
        desc: data.type_name,// 分享时的简介
        type: 'link',
        dataUrl: '',
        success: function (res) {
          console.log("onMenuShareAppMessage-success", res)
        },
        cancel: function (error) {
          console.log("onMenuShareAppMessage-error", error)
        }
      });

      //分享到朋友圈
      wx.updateTimelineShareData({
        title: data.title, // 分享时的标题
        link: share_href, // 分享时的链接
        imgUrl: share_icon, // 分享时的图标
        desc: data.type_name, // 分享时的简介
        success: function (res) {
          console.log("updateTimelineShareData-success", res)
        },
        cancel: function (error) {
          console.log("updateTimelineShareData-error", error)
        }
      });


      //分享给朋友
      wx.updateAppMessageShareData({
        title: data.title,// 分享时的标题
        link: share_href, // 分享时的链接
        imgUrl: share_icon, // 分享时的图标
        desc: data.type_name,// 分享时的简介
        success: function (res) {
          console.log("updateAppMessageShareData-success", res)
        },
        cancel: function (error) {
          console.log("updateAppMessageShareData-error", error)
        }
      });



    })
  },


  //判断是否为空
  isEmpty(val) {
    if (val == "" || val == null || val == undefined || val == " ")
      return true;
    return false;
  },
  //倒计时
  countDown(obj, num) {
    if (num == 1) {
      var Timer = setInterval(() => {
        if (obj.code_time > 0) {
          obj.code_time--;
          obj.code_text = `${obj.code_time}秒后获取`;
          obj.code_flag = false;
        } else {
          obj.code_flag = true;
          obj.code_time = 60;
          obj.code_text = `重新获取`;
          clearInterval(Timer);
        }
      }, 1000)
    } else {
      var Timer = setInterval(() => {
        if (obj.re_code_time > 0) {
          obj.re_code_time--;
          obj.re_code_text = `${obj.re_code_time}秒后获取`;
          obj.re_code_flag = false;
        } else {
          obj.re_code_flag = true;
          obj.re_code_time = 60;
          obj.re_code_text = `重新获取`;
          clearInterval(Timer);
        }
      }, 1000)
    }

  },


  //判断是否登录
  isLogin(that) {
    if (this.isnull(that.$cookies.get("token"))) {
      return false;
    } else {
      return true;
    }
  },

  /* 是否手机号码*/
  isMobile(value) {
    // const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    const reg = /^(\+?0?86\-?)?1(3|4|5|6|7|8|9)[0-9](-|\s?)[0-9]{4}(-|\s?)[0-9]{4}$/;
    if (value == '' || value == undefined || value == null) {
      return false;
    } else {
      if (!reg.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  },
  /* 隐藏手机号中间四位*/
  hideMobile(value) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return value.replace(reg, "$1****$2");
  },

  //时间戳  转 年月日
  add0(m) {
    return m < 10 ? '0' + m : m
  },
  format(timestamp, type) {
    //timestamp是整数，否则要parseInt转换
    var time = new Date(timestamp);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    if (type == 'yyyy-mm-dd') {
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    } else if (type == 'mm-dd') {
      return this.add0(m) + '-' + this.add0(d);
    } else if (type == 'yyyy-mm-dd/hh:mm:ss') {
      return y + '-' + this.add0(m) + '-' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm) + ':' + this.add0(s);
    }
  },

  //获取链接上参数
  getParam(variable) {
    var query = window.location.search.substring(1);
    var query3 = window.location.href.split("?");
    query = query + '&' + query3[1] + '&' + query3[2];
    console.log("param：" + query);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return (false);
  },


  //判断是否为ios
  isIOS() {
    var isIphone = navigator.userAgent.includes('iPhone');
    var isIpad = navigator.userAgent.includes('iPad');
    return isIphone || isIpad;
  },


  //判断当前域名
  checkCurrUrl() {
    console.log(window.location.href.split("#"));
    var href = window.location.href.split("#")[0];
    if (href.indexOf('localhost') < 0 || href.indexOf('bangtiyu.com') < 0) {
      console.log("跳转正式域名");

    }
  },

  // 验证密码 长度为6~14位字符，字母/数值及标点符号至少包含2种， 不允许有空格、中文。
  validatePassword(val) {
    var str = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5\/\\]{6,20}$/;
    return str.test(val)
  },

  // 验证是不是合法邮箱
  isEmail(val) {
    var str = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
    return str.test(val);
  },

  // 获取本地ip
  async getLocalIp() {
    var res = await $http.request(api.get_ip)
    if (res.code == 200) {
      return res.data;
    } else {
      console.log(res.msg);
      return false;
    }
  },
  /**
 * @description 数字千分位
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
  formatThousands(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '')
    let n = !isFinite(+number) ? 0 : +number
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    let dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = ''
    let toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec)
      return '' + Math.round(n * k) / k
    }
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    let re = /(-?\d+)(\d{3})/
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, '$1' + sep + '$2')
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
  },

  /**
   * 格式化文件大小
   */
  formatFileSize(num) {
    let sizeStr = '0 KB';
    let sizeKb = 0;
    let sizeMb = 0;
    let sizeGb = 0;
    sizeKb = num / 1024;
    sizeMb = num / 1024 / 1024;
    sizeGb = num / 1024 / 1024 / 1024;

    if (sizeKb >= 0) {
      sizeStr = sizeKb.toFixed(2) + 'KB';
    }
    if (sizeMb >= 1 && sizeMb < 1024) {
      sizeStr = sizeMb.toFixed(2) + 'MB';
    }
    if (sizeGb >= 1) {
      sizeStr = sizeGb.toFixed(2) + 'GB';
    }
    return sizeStr;
  },

  //获取屏幕高度
  getWindowConfig() {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    if (typeof windowWidth !== 'number') {
      if (document.compatMode === 'CSS1Compat') {
        windowWidth = document.documentElement.clientWidth;
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowWidth = document.body.clientWidth;
        windowHeight = window.body.clientHeight;
      }
    }
    return {
      windowWidth: windowWidth,
      windowHeight: windowHeight
    }
  },

  /**
   * 获取随机uuid
   * @param {*} n 
   * @returns 
   */
  uuid(n) {
    var str = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
      'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var res = '';
    for (var i = 0; i < n; i++) {
      var id = Math.ceil(Math.random() * 35);
      res += str[id];
    }
    return res;
  },

  setBodyFontSize() {
    $(function () {
      var ww = $(window).width();
      console.log(ww);
      if (ww <= 768) {
        console.log('这是wap端~');
        window['adaptive'].desinWidth = 750;//设计稿宽度
        window['adaptive'].baseFont = 28;//默认字体大小
        window['adaptive'].maxWidth = 750;//最大宽度
        window['adaptive'].init();
      } else {
        console.log('这是pc端~');
        window['adaptive'].desinWidth = 1340;//设计稿宽度
        window['adaptive'].baseFont = 14;//默认字体大小
        window['adaptive'].maxWidth = 1340;//最大宽度
        window['adaptive'].init();
      }
    });

    $(window).resize(function () {          //当浏览器大小变化时
      // console.log($(window).width());          //浏览器时下窗口可视区域高度
      // console.log($(document).width());        //浏览器时下窗口文档的高度
      // console.log($(document.body).width());   //浏览器时下窗口文档body的高度
      // console.log($(document.body).outerWidth(true)); //浏览器时下窗口文档body的总高度 包括border padding margin
      var ww = $(window).width();
      if (ww <= 768) {
        console.log('变成wap端啦~');
        window['adaptive'].desinWidth = 750;//设计稿宽度
        window['adaptive'].baseFont = 28;//默认字体大小
        window['adaptive'].maxWidth = 750;//最大宽度
        window['adaptive'].init();
      } else {
        console.log('变成pc端啦~');
        window['adaptive'].desinWidth = 1340;//设计稿宽度
        window['adaptive'].baseFont = 14;//默认字体大小
        window['adaptive'].maxWidth = 1340;//最大宽度
        window['adaptive'].init();
      }
    });
  },

  /**
   * 消息提示
   * @param {*} msg 
   * @param {*} type 0 成功，1 失败
   */
  reminderMsg(msg, type = 0) {
    if (!!msgTarget) msgTarget.close();
    if (type == 0) msgTarget = Message.success(msg);
    if (type == 1) msgTarget = Message.error(msg);
  }
}
