<template>
	<div class="m-waterfall-wrap Waterfall" :style="`background-color: ${backgroundColor}; width: ${totalWidth}px; height: ${height}px;`">
		<template v-for="(item, index) in imagesProperty">
			<!-- 图片 -->
			<div :key="index" class="u-img"
				:style="`width: ${imageWidth}px; top: ${item && item.top}px; left: ${ item && item.left }px; opacity: ${!!item.top ? 1 : 0}`"
				@click="handleGoDetail(item)">
				<!-- 图片内容 -->
				<div :style="`width: 100%;`">
					<el-image class="u-img" style="width: 100%" :src="!!item ? item.modelDisplayPicUrl : ''" fit="cover" @load="ImgOnLoad($event, index, item)"
						@error="ImgOnLoad($event, index, item)" />
					<!-- <div class="info-view"></div> -->
					<div class="tag flex-c-c">
            <!--免费-->
						<div class="free" v-if="!!item && item.modelChargeStatu == 1">{{$t('modelDetail.mf')}}</div>
						<div class="VIP" v-else-if="!!item && item.modelChargeStatu == 2">VIP</div>
						<div class="flex-c" v-else>
							<img class="count-logo" src="../assets/images/count-logo.png" alt="" />
							<div class="count">{{!!item ? item.modelPrice : '0.00' }}</div>
						</div>
					</div>
				</div>

				<div :style="`margin-top: ${item.height}px; background: ${bgColor};`" class="item-content">
					<div class="model-title">{{ item.modelName }}</div>
					<div class="model-content">
						<!-- 头像和名称 -->
						<div class="user-info flex-c">
							<!-- <el-image :src="item.modelDisplayPicUrl"></el-image> -->
							<el-image :src="item.modelAuthorAvatar || defaultAvatar"></el-image>
							<span>{{ item.modelAuthorNickName }}</span>
						</div>
						<!-- 收藏数量 -->
						<div class="collect-info flex-c">
							<el-image :src="require('@/assets/images/collect.png')"></el-image>
							<span>{{ item.enjoy }}</span>
						</div>
					</div>

          <slot v-bind:item="item"></slot>
				</div>
				<!-- END -->
			</div>
		</template>
	</div>
</template>
<script>
	/*
		    主要使用js进行计算，新的图片每次都添加在最短那列的末尾
		  */
	import { mapState } from 'vuex';
	export default {
		name: 'Waterfall',
		props: {
			imageData: {
				// 瀑布流的图片数组
				type: Array,
				required: true,
				default: () => {
					return [];
				},
			},
			columnCount: {
				// 瀑布流要划分的列数
				type: Number,
				default: 3,
			},
			columnGap: {
				// 瀑布流各列之间的间隙
				type: Number,
				default: 30,
			},
			totalWidth: {
				// 瀑布流区域的总宽度
				type: Number,
				default: 1200,
			},
			backgroundColor: {
				// 瀑布流区域背景填充色
				type: String,
				default: '#F2F4F8',
			},
			currentPage: Number,

			// 瀑布流列表是否渲染完成
			listLoadin: Boolean,

      bgColor: {
        type: String,
        default: '#f3f4f5'
      }
		},
		data() {
			return {
				imagesProperty: [], // 重构好的瀑布流数组
				preImages: [], // 最后一行的内容的高度

        imgLoadOk: 0,
			};
		},

		computed: {
			...mapState({
				defaultAvatar: (state) => state.defaultAvatar,
			}),
			//获取图片长度 总宽度 取间隔
			imageWidth() {
				return (this.totalWidth - (this.columnCount - 1) * this.columnGap) / this.columnCount;
			},
			height() {
				return Math.max(...this.preImages) + this.columnGap;
			},
		},
		watch: {
			imageData(to) {
				console.log('瀑布流 watch>>>>>', this.imageData.length, this.imagesProperty.length);
				if (this.imageData.length > 0) {
					this.onPreload(this.imagesProperty.length);
					// this.imagesProperty.splice(to.length);
				}
			},
		},
		created() {
			// 每次刷新列表时需要清除上次渲染留下的最后一行的内容的高度。
			this.preImages = [];
			this.imagesProperty = [];
			// this.$emit('update:imageData', []);
			if (this.imageData.length > 0) {
        this.onPreload(this.imagesProperty.length);
        // this.imagesProperty.splice(this.imageData.length);
      }
		},
    mounted() {

    },
		methods: {
      /**
       * 废弃
       */
			getPosition(i, height) {
				return new Promise((resolve, reject) => {
					// 获取图片位置信息（top，left）
					let contentHeight = 100;
					if (i < this.columnCount) {
						this.$set(this.preImages, i, this.columnGap + height + contentHeight);
						setTimeout(() => {
							resolve({
								top: this.columnGap,
								left: (this.imageWidth + this.columnGap) * i,
							});
						}, 10);
					} else {
						let top = Math.min(...this.preImages);
						var index = 0;

						for (let n = 0; n < this.preImages.length; n++) {
							if (this.preImages[n] === top) {
								index = n;
								this.$set(this.preImages, index, top + this.columnGap + height + contentHeight);
								break;
							}
						}

						setTimeout(() => {
							resolve({
								top: top + this.columnGap,
								left: (this.imageWidth + this.columnGap) * index,
							});
						}, 10);
					}
				});
			},
      /**
       * 废弃
       */
			onLoad(url, i, item) {
				return new Promise((resolve) => {
					const image = new Image();
					image.src = url;
					const that = this;
					image.onload = async function () {
						// 图片加载完成时执行，此时可通过image.width和image.height获取到图片原始宽高
						var height = image.height / (image.width / that.imageWidth);
						let targetPosition = await that.getPosition(i, height);
						that.$set(that.imagesProperty, i, {
							// 存储图片宽高和位置信息
							...item,
							width: that.imageWidth,
							height: height,
							modelDisplayPicUrl: url,
							...targetPosition,
						});
						// console.log('this:', this === image) // true 函数运行时所在的对象即img
						resolve('load');
					};
					image.onerror = async function (error) {
						console.log('加载失败');
						let targetPosition = await that.getPosition(i, 383);
						that.$set(that.imagesProperty, i, {
							// 存储图片宽高和位置信息
							...item,
							width: that.imageWidth,
							height: 383,
							modelDisplayPicUrl: '',
							...targetPosition,
						});
						resolve('load');
					};
				});
			},

			onPreload(length) {
				const that = this;
				const len = this.imageData.length;
				const Propertylen = this.imagesProperty.length;
				let i = length || 0;
				let imageList = [];

				this.imageData.forEach((item, i) => {
          if (i > Propertylen - 1 || Propertylen == 0) {
            imageList.push({
              // 存储图片宽高和位置信息
              ...item,
              width: that.imageWidth,
              // height: height ,
              modelDisplayPicUrl: item.modelDisplayPicUrl || '',
              top: 0,
              left: 0,
            });
          }
        });
				this.imagesProperty = this.imagesProperty.concat(imageList);
				// for (let i = 0; i < len; i++) {
				//   if (i > Propertylen - 1 || Propertylen == 0) {
				//     await this.onLoad(
				//       this.imageData[i].modelDisplayPicUrl || '',
				//       i,
				//       this.imageData[i]
				//     )
				//   }
				// }
			},
			handleGoDetail(item) {
				this.$router.push({
					path: `/modelDetail/${item.modelId}`,
				});
			},

			ImgOnLoad(e, index, item) {
				// console.log(e.target.height);
				const that = this;
        this.imgLoadOk++
        if (this.imgLoadOk == this.imagesProperty.length) {
          let el = $('.Waterfall .el-image.u-img');
          let contentHeight = 100;
          setTimeout(() => {
            el.toArray().forEach((imgEl, i) => {
              let eiImg = $(imgEl).find('img')[0];
              let elHeight = !!eiImg ? eiImg.height : 383;
              let _item = this.imagesProperty[i];

              if (i < that.columnCount) {
                that.$set(that.preImages, i, that.columnGap + elHeight + contentHeight);
                _item.top = that.columnGap;
                _item.height = elHeight;
                _item.left = (that.imageWidth + that.columnGap) * i;
              } else {
                let top = Math.min(...that.preImages);
                _item.top = top + that.columnGap;
                for (let n = 0; n < that.preImages.length; n++) {
                  if (that.preImages[n] === top) {
                    that.$set(that.preImages, n, top + that.columnGap + elHeight + contentHeight);
                    _item.height = elHeight;
                    _item.left = (that.imageWidth + that.columnGap) * n;
                    break;
                  }
                }
              }
            })
          }, 10);
        }

        return;
        // 下面这个偶尔出现错位
				let height = e.target.height || 383;
				let contentHeight = 100;
				if (index < that.columnCount) {
					if (!that.preImages[index]) {
						that.$set(that.preImages, index, that.columnGap + height + contentHeight);
						item.top = that.columnGap;
						item.height = height;
						item.left = (that.imageWidth + that.columnGap) * index;
					} else {
            // 处理首次第一页加载，preImages 中还没有值时
						item.top = that.preImages[index] + that.columnGap;
						that.$set(
							that.preImages,
							index,
							that.preImages[index] + that.columnGap + height + contentHeight
						);
						item.height = height;
						item.left = (that.imageWidth + that.columnGap) * index;
					}
				} else {
					let top = Math.min(...that.preImages);
					if (isNaN(top)) {
            // 处理首次第一页加载，preImages 中还没有值时
						let _index = index % 5;
					  let _top = that.preImages[_index];
						if (!_top) {
							that.$set(that.preImages, _index, that.columnGap + height + contentHeight);
							item.top = that.columnGap;
							item.height = height;
							item.left = (that.imageWidth + that.columnGap) * _index;
						} else {
							item.top = _top + that.columnGap;
							that.$set(that.preImages, _index, _top + that.columnGap + height + contentHeight);
							item.height = height;
							item.left = (that.imageWidth + that.columnGap) * _index;
						}
					} else {
						item.top = top + that.columnGap;
						for (let n = 0; n < that.preImages.length; n++) {
							if (that.preImages[n] === top) {
								that.$set(that.preImages, n, top + that.columnGap + height + contentHeight);
								item.height = height;
								item.left = (that.imageWidth + that.columnGap) * n;
								break;
							}
						}
					}
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.m-waterfall-wrap {
		position: relative;

		.u-img {
			position: absolute;
			display: inline-block;
			object-fit: contain;
			vertical-align: bottom;
			height: auto;

      .u-img {
        border-radius: 0;
      }
		}
	}

	.info-view {
		height: 70px;
		width: 94%;
		background: red;
		position: relative;
	}
</style>
