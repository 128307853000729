<template>
  <div class="no-more">{{text || defaultText}}</div>
</template>

<script>
export default {
  name: "no-more",
  computed:{
  },
  props:{
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultText: this.$t('common.noMore'),
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.no-more {
  width: 100%;
  padding: 30px;
  text-align: center;
  font-size: 16px;
  color: #999;
}
</style>
