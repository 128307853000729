<template>
  <div class="footer-view flex-c-c">
    <div style="width: 1200px">
      <div class="flex-c-b">
        <!-- 左边 -->
        <div class="flex-c-b" style="width: 980px;">
          <!-- 关于我们 -->
          <div>
            <!-- 关于我们 -->
            <div class="title mb10">{{$t('footer.gywm')}}</div>
            <div class="flex-c lable">
              <!-- 公司介绍 -->
              <div class="mr25 lable_text" @click="toTapUrl('/aboutUs')">{{$t('footer.gsjs')}}</div>
              <!-- 加入我们 -->
              <div class="lable_text" @click="toTapUrl('/joinUs')">{{$t('footer.jrwm')}}</div>
            </div>
          </div>

          <!-- 支持服务 -->
          <div class="mr25">
            <!-- 支持服务 -->
            <div class="title mb10">{{$t('footer.zcfw')}}</div>
            <div class="flex-c lable">
              <!-- 帮助中心 -->
              <div class="mr25 lable_text" @click="toTapUrl('/helpCenter', {type: 1})">{{$t('footer.bzzx')}}</div>
              <!-- 新手指南 -->
              <div class="mr25 lable_text" @click="toTapUrl('/helpCenter', {type: 2})">{{$t('footer.xszn')}}</div>
              <!-- 意见反馈 -->
              <div class="lable_text" @click="toTapUrl('/feedback')">{{$t('footer.yjfk')}}</div>
            </div>
          </div>

          <!-- 服务声明 -->
          <div style="max-width: 400px;">
            <div class="title mb10">{{$t('footer.fwxy')}}</div>
            <div class="flex-c lable">
              <!-- 用户协议 -->
              <div class="mr25 lable_text" @click="toTapUrl('/agreement',{type: 1})">{{$t('footer.yhxy')}}</div>
              <!-- 隐私政策 -->
              <div class="mr25 lable_text" @click="toTapUrl('/agreement',{type: 2})">{{$t('footer.yszc')}}</div>
              <!-- 版权声明 -->
              <div class="mr25 lable_text" @click="toTapUrl('/agreement',{type: 3})">{{$t('footer.bqsm')}}</div>
              <!-- 会员服务协议 -->
              <div class="lable_text" @click="toTapUrl('/agreement',{type: 4})">{{$t('footer.hyfwxy')}}</div>
            </div>
          </div>
        </div>
        <!-- 左边 end -->

        <!-- 分割线 -->
        <div class="flex-c" style="margin-bottom: -24px;">
          <div class="lineHi"></div>
          <div class="lineBl"></div>
        </div>
        <!-- 分割线 end -->

        <!-- logo -->
        <img class="logo" src="@/assets/images/logo02.png" alt="" style="margin-bottom: -24px;"/>
        <!-- logo end -->
      </div>
      <div class="Copyright-box" style="margin-top: 5px;">
        <p class="Copyright">Copyright © 深圳市章鱼工场科技有限公司
          <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff;">粤ICP备2023010580号-1</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "footerView",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      token: state => state.token
    }),
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      setLoginStatus: "setLoginStatus",
      setAgreement: 'setAgreement',
      setHelpType: 'setHelpType',
    }),
    //跳转链接
    toTapUrl(path, param){
      ///防相同路由跳转
      if(this.$route.path !== path){
        if(path === '/feedback' && !this.token){
          this.setLoginStatus(true);
          return;
        }
        if(param){
          path = path + `?type=${param.type}`;
        }
        this.$router.push({
          path: path,
          query: param,
        })
      }else{
        if(path == '/agreement'){
          this.setAgreement(param.type);
        }if(path == '/helpCenter'){
          this.setHelpType(param.type);
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
.lable_text{
  cursor: pointer;
  word-break: break-word;
}
.lable_text:hover{
  text-decoration: underline;
  color: #fff;
}

.Copyright-box {
  color: #fff;
    font-size: 14px;
}
</style>
