import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import userInfoModule from './modules/userInfo';
import search from './modules/search';
import dict from './modules/dict';
import webSocketState from './modules/webSocketState';
import { Message } from 'element-ui';

// 引入api
import api from '../api/api.js';

// 引入异步请求文件
import request from '../utils/request.js';

Vue.use(Vuex)

const PERSIST_PATHS = ['token', 'secretKey', 'userInfoModule.userInfo', 'dict.modelClass','payEndTime']
export default new Vuex.Store({
  state: {
    //登录后的 用户秘钥
    token: '',
    //socket 秘钥
    secretKey: '',
    //登录弹窗是否显示
    loginStatus:false,
    //购物车数量
    carNum:0,
    //下单结算时间:
    payEndTime:0,
    //协议类型： 1用户协议， 2隐私政策，3版权声明
    agreement: 0,
    //帮助中心分类
    helpType: 0,//1 帮助列表， 2新手指南
    // 默认头像
    defaultAvatar: require('@/assets/images/ic_launcher.png'),
    // 显示上传模型
    showUploadModel: false,
    // 删除中的模型id
    modelDeleting: '',
    // 触发了删除我的模型
    eventDelModel: 0,
  },
  getters: {
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_SECRET_KEY(state, data) {
      state.secretKey = data;
    },
    SET_LOGIN_STATUS(state, data){
      console.log(data);
      state.loginStatus = data;
    },
    SET_CAR_NUM(state, data){
      state.carNum = data;
    },
    SET_PAY_END(state, data){
      state.payEndTime = data;
    },
    SET_AGREEMENT(state, data){
      state.agreement = data;
    },
    SET_HELP_TYPE(state, data){
      state.helpType = data;
    },
    SET_SHOW_UPLOAD_MODEL(state, data){
      state.showUploadModel = data;
    },
    SET_MODEL_DELETING(state, data) {
      state.modelDeleting = data;
    },
    SET_EVENT_DEL_MODEL(state, data) {
      state.eventDelModel++;
    },
  },
  actions: {
    // 设置token
    setValue({ commit }, data) {
      commit(data[0], data[1]);
      return data;
    },
    // 设置token
    setToken({ commit }, data) {
      commit('SET_TOKEN', data);
      return data;
    },
    // 设置socket链接安全秘钥
    setSecretKey({ commit }, data) {
      commit('SET_SECRET_KEY', data);
    },
    //设置登录弹窗
    setLoginStatus({ commit }, data) {
      commit('SET_LOGIN_STATUS', data);
    },
    //设置购物车刷领
    setCarNum({ commit,state }) {
      if(state.token){
        // console.log('设置')
        let params = {
         
        }
        request.request(api.getShoppingCartCount, params, "post").then(res=>{
          if(res.code == 200){
            commit('SET_CAR_NUM', res.data);
          }else{
            commit('SET_CAR_NUM', 0);
          }
        })
      }else{
        // console.log('没有登录')
      }
    },
    //设置协议
    setAgreement({ commit }, data) {
      commit('SET_AGREEMENT', data);
    },
    //帮助中心分类
    setHelpType({ commit }, data) {
      commit('SET_HELP_TYPE', data);
    },
    // 设置是否显示上传模型
    setShowUploadModel({ commit }, data) {
      commit('SET_SHOW_UPLOAD_MODEL', data);
    },
    // 设置删除中的模型id
    setModelDeleting({ commit }, data) {
      commit('SET_MODEL_DELETING', data);
    },
    // 点了删除我的模型回调, 用于更新个人信息
    changeEventDelModel({ commit }) {
      commit('SET_EVENT_DEL_MODEL');
    },

    // 删除上传的oss文件
    deleteFiles({ commit }, filePathArr) {
      let params = {
        filePath: filePathArr
      };
      request.request(api.deleteFiles, params, 'post').then(res => {
        if (res.code == 200) {} else {
          // Message.error(res.msg);
        }
      });
    }
  },
  modules: {
    userInfoModule,
    search,
    dict,
    webSocketState,
  },
  plugins: [
    // 持久化储存插件
    createPersistedState({
      storage: window.sessionStorage,
      paths: PERSIST_PATHS
    }),
  ],
})
