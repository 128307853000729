import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // elementui 的中文语言包
const cn = {
  //公共的
  common: {
    all: '全部',
    loading: '加载中',
    noData: '暂无数据',
    addImg: '添加图片',
    zzsc: '正在上传',
    submit: '提交',
    cancel: '取消',
    submit_success: '提交成功',
    confirm: '确定',
    qxz: '请选择',
    qsr: '请输入',
    search: '搜索',
    delete: '删除',
    hint: '提示',
    home: '首页',
    zztj: '正在提交',
    noMore: '没有更多了',
    deleting: '删除中',
    time: '时间',
    imgFormatErr: '图片格式只能是',
    czcg: '操作成功',
    save: '保存',
    ckxq: '查看详情',
    shouqi: '收起',
    zhankai: '展开',
    zwmx: '暂无模型',
    zwsp: '暂无商品',
    weChat: '微信',
    zyhydd: '章鱼核移动端',
  },
  header: {
    uploadModel: '上传模型',
    workbench: '工作台',
    shoppingCart: '购物车',
    day: '天',
    register: '注册',
    login: '登录',
    modelBase: '模型库',
    modelColumn: '模型专栏',
    productLibrary: '商城',
    searchPlaceholder: '搜索你感兴趣的内容',
    becomeDue: '到期',
    renew: '续费',
    index: '首页',

    bdsj: '绑定手机',
    bd: '绑定',

    smdl: '扫码登录',
    qsy: '请使用',
    smewm: '扫描二维码',

  },
  login: {
    title: '登录你的章鱼核账号',
    myzhmfzc: '没有账号？免费注册',
    sjyzmdl: '手机验证码登录',
    yxyzmdl: '邮箱验证码登录',
    zhmmdl: '账号密码登录',
    qsrsjh: '请输入手机号',
    qsryx: '请输入邮箱',
    qsrzqyx: '请输入正确的邮箱',
    qsrsjhOryx: '请输入您的手机号/邮箱',
    qsrzqsjh: '请输入正确的手机号/邮箱',
    qsryzm: '请输入验证码',
    qsrmm: '请输入密码',
    hqyzm: '获取验证码',
    fsz: '发送中',
    khq: 'S后可获取',
    tyysxy: '请同意用户与隐私协议',
    grzx: '个人中心',
    tcdl: '退出登录',
    smcg: '扫码成功',
    qzsjsqr: '请在手机上确认',
    xczddl: '下次自动登录',
    loginText: '登录',
    agreementText01: '我已阅读并同意章鱼核',
    agreementText02: '《用户协议》',
    agreementText03: '与',
    agreementText04: '《隐私政策》',
    otherLogin: '其他登录方式',
    wxLogin: '微信登录',
    qqLogin: 'QQ登录',
    weiboLogin: '微博登录',
    srsjhOryx: '输入手机/邮箱',
    srndmm: '输入密码(6~18位字符)',
    wjmm: '忘记密码?',
    qdtcm: '您确定要退出吗, 是否继续?'
  },
  home: {
    tj: '精选',
    zx: '最新',
    zr: '最热',
    gd: '更多',
    zhpx: '综合排序',
    cymxzl: '创意·模型专栏',
    hl3Dmx: '海量·3D模型',
    gdzl: '更多专栏',
  },
  zhuanLan: {
    title: '全部模型专栏',
    noZhuanLan: '暂无专栏',
  },
  // 模型详情
  modelDetail: {
    scy: '上传于',
    zyb: '章鱼币',
    fl: '分类',
    mf: '免费',
    collect_success: '收藏成功',
    collect_cancel: '取消成功',
    dbxz: '打包下载',
    plxz: '批量下载',
    yjqp: '一键切片',
    mxms: '模型描述',
    hyzx: '会员专享',
    hyzx_desc: '升级会员获取，海量模型任意下载。',
    ktvip: '开通会员',
    wj: '文件',
    spxg: '实拍效果',
    pl: '评论',
    qpg01: '共',
    qpg02: '个模型文件',
    gsp: '个实拍',
    tpl: '条评论',
    yl: '预览',
    qp: '切片',
    download: '下载',
    fsp: '发实拍',
    fbspxg: '发布实拍效果',
    sctp: '上传图片',
    qscsptp: '请上传实拍图片',
    zan: '赞',
    huifu: '回复',
    xpl: '写评论',
    placeholder1: '说点什么',
    placeholder2: '内容',
    emoji: '表情',
    img: '图片',
    qsrplnr: '请输入评论内容',
    ljgm: '立即购买',
    buy: '购买',
    zybbz: '章鱼币不足，去充值',
    yuan: '元',
    qxzzybtc: '请选择章鱼币套餐',
    gmcg: '购买成功',
    tjmx: '精选模型',
    hyh: '换一换',
    ydcc: '云端存储',
    bdcc: '本地存储',
    xzcg: '下载成功',
    qxzxzwj: '请选择下载文件',
    sccg: '删除成功',
    qdscmx: '确定删除该模型吗？',
    ljqp: '立即切片',
  },


  // 模型发布
  modelPublish: {
    title: '上传模型',
    mxly: '模型来源',
    ycmx: '原创模型',
    fxmx: '分享模型',
    ecczmx: '二次创作模型',
    bqsm: '版权声明',
    xzgxxy: '请选择知识共享许可协议',
    dj: '定价',
    sf: '收费',
    mf: '免费',
    srzybjg: '请输入章鱼币价格',
    yczm: '原创证明',
    yczm_tips: '上传《作品登记证书》或其他原创证明，模型将被优先审核并精选！',
    sczm: '上传证明',
    scmx: '上传模型',
    add: '添加',
    file_tips01: '每次最多可上传500MB大小的文件',
    file_tips02: '目前支持的模型文件格式: ',
    file_type_err: '上传的文件格式有误',
    mxmc: '模型名称',
    mxmc_placeholder: '请输入60个字以内的名称',
    mxlb: '模型类别',
    mxlb_placeholder: '请选择模型类别',
    yssz: '隐私设定',
    gk: '公开',
    zy: '自用',
    img_list: '图集',
    img_list_tips: '最多可上传10张',
    sctj: '上传图集',
    qsctj: '请上传图集',
    desc: '描述',
    desc_placeholder: '简单的描述一下该模型吧...',
    ck: '查看',

    qxzmxly: '请选择模型来源',
    qscmxzm: '请上传模型证明',
    qscmxwj: '请上传模型文件',
    qscmxwjslt: '请上传模型文件缩略图',

    scwc: '上传完成',
    scsb: '上传失败',
    cckjbz: '您的存储空间不足，可到个人中心存储空间进行扩容',
  },

  //商城
  goodsList: {
    allClass: '全部分类',
    qxsc: '取消收藏',
    sc_goods: '收藏',
    yxzsp: '已选择商品',
    jrgwc: '加入购物车',
    cwdshdzxz: '从我的收货地中选择',
    tjdz: '添加地址',
    qxzdz: '请选择地址',
    spxq: '商品详情',
    sppj: '商品评价',
    kf: '客服',
    rmjx: '热门精选',
    jrpp: '进入品牌',
    sort: '排序',
    zh: '综合',
    xl: '销量',
    price: '价格',
    hpd: '好评度',
    zhpj: '综合评价',
    leibie: '类别',
    fenlei: '分类',
    rfk: '人付款',
    ps: '配送',
    fh: '发货',
    yf: '邮费',
    myf: '免运费',
    zwpj: '暂无评价',
    rsc: '人收藏',
    qi: '起',
    qdscgdz: '确定要删除该地址吗？',
    kcbz: '库存不足',
    xtqhsjxxyz: '选填，请先和商家协商一致，付款后商家可见',
    qxzgg: '请选择规格',
    zwcp: '暂无产品',
    ksp: '款商品',
    zhuanqu: '专区',
  },


  user: {
    qsctx: '请上传头像',
    qsrnc: '请输入昵称',
    qsrbq: '请输入标签',
    changePwdTitle: '修改密码',
    changePwdTips: '为了您的账号安全，我们将发送短信验证码到您的手机：',
    sendYzm: '发送验证码',
    reSendYzm: '重新发送',
    sendCodeSuccessful: '验证码发送成功',
    inputCode: '请输入验证码',
    inputOldPwd: '请输入原密码',
    inputNewPwd: '请输入新密码',
    inputNewPwdAgain: '请再次输入新密码',
    pwdDifferent: '两次密码不一致，请重新输入',
    unableSetPwd: '您尚未绑定手机号，暂不能设置密码',
    pwdLengthTips: '密码由6-20个英文字母、数字或符号组成',
    tips: '温馨提示',
    cancelAccountTips: '注销后账号所有数据将被销毁并不可找回，是否确定注销该账号?',
    unbindingWxTips: '确定要解绑微信吗?',
    unbindingQqTips: '确定要解绑QQ吗?',
    unbindingWbTips: '确定要解绑微博吗?',
    confirm: '确定',
    cancel: '取消',
    cancelAccount: '注销帐号',
    cancelAccountText: '注销后账号所有数据将被销毁并不可找回，请谨慎操作。',

    bindPageTitle: '绑定账号',
    currentEmail: '当前邮箱',
    currentMobile: '当前手机号',
    change: '更换',
    thirdPartTitle: '第三方绑定账号',
    thirdPartNotes: '绑定第三方帐号，可以直接登录章鱼核，如果当前帐号在对应站点处于登录状态，需退出登录后，才能重新绑定。',
    binding: '绑定',
    unbinding: '解除绑定',
    submit: '提交',
    inputNewMobile: '请输入新手机号码',
    mobileFormat: '手机号格式不正确',
    changeEmailTips: '为了您的账号安全，我们将发送验证码到您的邮箱：',
    inputNewEmail: '请输入新邮箱地址',
    inputEmailCode: '请输入新邮箱验证码',
    emailFormat: '邮箱格式不正确',
    emailRepeat: '新邮箱不能与原邮箱相同',
    emailVerified: '已验证',
    storageRule: '存储空间规则',
    storageTitle: '免费专享5GB空间',
    storageTime: '永久有效',
    rule: '规则',
    freeSpace: '可用空间',

    qpcc: '切片尺寸',
    qpdx: '切片大小',
    qpcs: '切片层数',
    tier: '层',
    jx: '机 型',
    xq: '详情',

    grzx: '个人中心',
    grzl: '个人资料',
    grsz: '个人设置',
    sctx: '上传头像',
    nickname: '昵称',
    qsr_nickname: '请输入昵称',
    bq: '个性签名',
    hdqmhrgdrkjno: '好的签名会让更多人看见你哦',
    yzsjh: '验证手机号',
    ghsjh: '更换手机号',
    bdsjh: '绑定手机号',
    szzh: '设置账号',
    xgmm: '修改密码',
    yzyx: '验证邮箱',
    bdyx: '绑定邮箱',
    ghyx: '更换邮箱',

    yykj: '已用空间：',
    dq: '到期',

    qp_cancel: '取消成功',

    wddd: '我的订单',
    wdmx: '我的模型',
    wdqp: '我的切片',
    wdsc: '我的收藏',
    wdpj: '商品订单评价',

    mx: '模型',
    ssmxmc: '搜索模型名称',
    scmx: '上传模型',
    cckj: '存储空间',
    zh: '账户',
    copy: '复制',
    zyb: '章鱼币',
    ycc: '云存储',

    qsrsjh: '请输入手机号',

    wdzyb: '我的章鱼币',
    qpzt: '切片状态',
    qpwxy: '切片响应中，请稍等',
    yqx: '已取消',
    cczjqxqprw: '此操作将取消该切片任务, 是否继续?',
    zwqpsj: '暂无切片数据',
    hp: '好评',
    cp: '差评',
    zp: '中评',
    content: '内容',
    img: '图片',
    priority: '优先',
    vipyxpdz: '会员优先排队中',
    lxkf: '联系客服',
    gzsj: '工作时间',
    txyj: '填写意见',

    qpsb: '切片失败',
    bkqx: '不可取消',
    ckqb: '查看全部',

    zwmxwjksc: '暂无模型文件可删除',
    sctx_size: '上传头像图片大小不能超过 2MB!',

    modelsize: '大小',
    xzsj: '下载时间',

    smbd: '扫码绑定',
    qsywxsm: '请使用"微信扫描二维码"',

    crcckj: '扩容存储空间',
    kthyxgdkj: '开通会员享更大空间',

    chongZhi: '充值',
    qbmx: '钱包明细',
    yxz: '已下载',
    wqp: "未切片",
    yqp: "已切片",
    scdycc: "上传到云存储",
    ysc: "已上传",
    cczjscmxwj: "此操作将删除该模型下所有文件",
    sfjx: "是否继续?",
    cczjscmxwj02: "此操作将删除该该模型文件",
    cczjscmxwj03: "此操作将删除该模型上传记录",
    yjpdsj: "预计排队时间",
    cxqp: "重新切片",
    addTime: "添加时间",
    scqprw: "此操作将删除该切片任务",
    qxqprw: "此操作将取消该切片任务",
    merchant: "商家",
    zwsj: '暂无商家',
    zwpjsj: '暂无评价数据',

    tpbncg: '图片不能超过',
    tpzdsc: '最多只能上传9张',
    pjcg: '评价成功',
    fbpjz: '发表评论中',

    user_yqp: "云切片",
    user_rjqp: "软件切片",
  },


  orders: {
    qbdd: '全部订单',
    j6yndd: '最近六月内订单',
    sixyqdd: '六个月前订单',
    sp: '商品',
    sl: '数量',
    sjzf: '实付款',
    ly: '留言',
    zt: '状态',
    jycz: '交易操作',
    ddh: '订单号',
    ddh02: '订单编号',
    none: '无',

    dzf: '待支付',
    jygb: '交易关闭',
    dfh: '待发货',
    dsh: '待收货',
    dpj: '待评价',
    ywc: '已完成',
    tksh: '退款/售后',
    pdz: '排队中',
    qpz: '切片中',

    qfk: '去付款',
    qxdd: '取消订单',
    xgdz: '修改地址',
    shdz: '收货地址',
    shou: '收',
    syxdz: '使用新地址',
    xgbdz: '修改本地址',
    qdxg: '确定修改',
    ddxq: '订单详情',
    qxyy: '取消原因',
    qxzqxyy: '请选择取消原因',
    wxts: '温馨提示',
    ddcgqxhwfhf: '订单成功取消后无法恢复',
    ddcgqxhwfhf02: '订单成功取消后，该订单已付金额将返还到您的支付账户，取消日5个工作日内退款完成',
    zbqx: '暂不取消',
    qdqx: '确定取消',
    qxzqxyy: '请选择取消原因',
    qxcg: '取消成功',
    qxz: '取消中',
    dd_tips01: '请您在',
    dd_tips02: '内完成支付。',
    minutes: '分',
    seconds: '秒',
    tjdd: '提交订单',
    fkcg: '付款成功',
    sjfh: '商家发货',
    qrsh: '确认收货',
    ddxx: '订单信息',
    mjly: '买家留言',
    tjsj: '提交时间',
    spxq: '商品详情',
    cz: '操作',
    spzj: '商品总价',
    yf: '运费',
    kd: '快递',
    fksj: '付款时间',
    fhsj: '发货时间',
    shsj: '收货时间',
    wcsj: '完成时间',
    qxsj: '取消时间',
    pg: '包裹',
    ydh: '运单号',
    ckwl: '查看物流',

    tkcg: '退款成功',
    sjyty: '商家已同意',
    ddsjsh: '等待商家收货',
    cxsq: '撤销申请',
    tkshxq: '退款/售后详情',
    mjsqtk: '买家申请退款',
    mjclthsq: '卖家处理退货申请',
    tkwb: '退款完毕',
    tkxq: '退款详情',
    cjsj: '成交时间',
    unitPrice: '单价',
    youFei: '邮费',
    tkbh: '退款编号',
    tkje: '退款金额',
    sqjs: '申请件数',
    yuan_yin: '原因',
    yao_qiu: '要求',
    shuo_ming: '说明',
    mjchsq: '买家撤回申请',
    sjjjtk: '商家拒绝退款',
    jjyy: '拒绝原因',
    mjth: '买家退货',
    scdd: '删除订单',
    qdyscgddm: '确定要删除该订单吗?',
    tkdzqx01: '退款将于',
    tkdzqx02: '个工作日诶退至您的账户',

    qrdd: '确认订单',
    swmrdz: '设置为默认地址',
    mrdz: '默认地址',
    gmjly: '给卖家留言',
    xzshdz: '新增收货地址',
    tjdz: '添加地址',
    bjdz: '编辑地址',
    shr: '收货人',
    sjhm: '手机号码',
    xzssq: '选择省-市-区-街道',
    xzgjdq: '选择国家-地区',
    bcbsy: '保存并使用',
    qsrshrmc: '请输入收货人名称',
    qsrshrdh: '请输入收货人电话',
    qxzshdz: '请选择收货地址',
    qsrshrxxdz: '请输入收货人详细地址',
    szcg: '设置成功',
    jsz: '寄送至',
    hyf: '含运费',
    bhyf: '不含运费',
    spzt: '商品状态',

    qxztklx: '请选择退款类型',
    qxztkyy: '请选择退款原因',
    qxzkdgs: '请选择快递公司',
    qtxwldh: '请填写物流单号',
    xgcg: '修改成功',
    xgsb: '修改失败',
    sqcg: '申请成功',
    shcg: '收货成功',
    tkpz: '退款凭证',

    cfh: '催发货',
    qrsh: '确认收货',
    ckwl: '查看物流',
    qpj: '去评价',
    shdh: '售后单号',
    wsh: '未售后',
    shz: '售后中',
    shwc: '售后完成',
    yjj: '已拒绝',
    tkthcg: '退款退货成功',
    dcl: '待处理',
    ddhj: '等待回寄',
    jjsq: '拒绝申请',
    lxpt: '联系平台',
    reapply: '重新申请',

    sqtk: '申请退款',
    tklx: '退款类型',
    xztklx: '选择退款类型',
    tkyy: '退款原因',
    xztkyy: '选择退款原因',
    tksm: '退款说明',
    qsrtkyy: '请输入退款原因',
    srjdhspm: '输入街道、乡镇、或商品名称',
    lrsztayg: '例如：深圳 天安云谷',
    nytjtksqddcl: '您已提交退款申请，等待商家处理',
    tytk: '同意退款',
    tytk_tips011: '商家已同意退款，请在',
    tytk_tips012: '内寄回',
    tytk_tips02: '退款商品已寄出，商家收到后会尽快给您退款操作',
    tytk_tips03: '商家同意您的退款申请，退款金额已原路退回',
    tytk_tips04: '商家拒绝了您的退款申请',
    tytk_tips05: '您已撤销退款申请，可重新发起退款申请',
    thdz: '退货地址',
    txwldh: '填写物流单号',
    order_qrshddts: "订单内有售后中商品将自动撤销退款申请，是否确认收货",
    kddh: "快递单号",
    kdgs: "快递公司",
    jtk: "退款",
    thtk: "退货退款",
    jihui: "寄回",
    qsrkddh: "请输入快递单号",
    sfcxgshdd: "是否撤销该售后订单?",
    cxz: "撤销中",

    gn: '国内',
    hw: '海外',
  },

  // 评价订单
  commentOrder: {
    pjdd: '评价订单',
    sppf: '商品评分',
    pjsd: '评价晒单',
    fxtyxd_content: '分享体验心得，给万千想买的人一个参考~',
    zdksc: '最多可上传9张，小大不超过10MB',
    fbpl: '发表评论',
    plnrbnwk: '评论内容不能为空',
  },

  // 物流
  logistics: {
    gsp: '个商品',
  },
  other: {
    joinTip: '个人用户可以通过在线客服的方式，快速与我们取得联络。也可以通过以下方式联系我们:',
  },

  // 工作台
  workbenches: {
    gzt: '工作台',
    kx: '空闲',
    jxz: '进行中',
    zt: '暂停',
    ywc: '已完成',
    dkj: '离线',
    gz: '故障',
    wwc: '未完成',
    zz: '终止',

    copy: '复制',
    copy_success: '复制成功',
    dysb: '打印设备',
    dyrw: '打印任务',
    lsrw: '历史任务',
    cjdyrw: '创建打印任务',
    sbzt: '设备状态',
    jx: '机型',
    sssbmc: '搜索设备名称/ID',
    tjsb: '添加设备',
    dycs: '打印层数',
    ssrwmc: '搜索任务名称',
    sbmc: '设备名称',
    qsrsbmc: '请输入设备名称',
    qsrsbid: '请输入设备ID',

    // 添加打印任务
    rwmc: '任务名称',
    rwmcbnwk: '任务名称不能为空',
    yxqp: '已选切片',
    cc: '尺寸',
    dysc: '打印时长',
    xzsb: '选择设备',
    qxz: '去选择',
    tjqp: '添加切片',
    xyb: '下一步',
    qsrrwmc: '请输入任务名称',
    qtjqp: '请添加切片',
    qxzdysb: '请选择打印设备',

    ddy: '待打印',
    dyz: '打印中',
    gzz: '工作中',
    ygb: '已关闭',

    dyhs: '打印耗时',
    dyjd: '打印进度',
    gghqpwj: '光固化切片文件',
    fen: '份',
    device: '设备',
    tai: '台',
    dyzsc: '打印总时长',
    fz: '分钟',
    gghhcyl: '光固化耗材用量',
    ydysj: '已打印时间',
    sysj: '剩余时间',
    yjsyhc: '预计使用耗材',
    ysyhc: '已使用耗材',
    ztdy: '暂停打印',
    qd: '启动',
    zzdy: '终止打印',

    dy: '打印',
    wdy: '未打印',
    qrsfksdy: '是否确认开始打印，开始打印前请取出上一次打印完成的模型，若未取出将会影响模型的打印',
    bdcg: '绑定成功',
    bdsb: '绑定失败',
    ybdcdyj: '此打印机已被绑定',
    ztcg: '暂停成功',
    ztsb: '暂停失败',
    jxcg: '继续成功',
    jxsb: '继续失败',
    zzcg: '终止成功',
    zzsb: '终止失败',

    qpwjg01: '切片文件共',
    qpwjg02: '个',
    sfscgqp: '是否移除此切片？',
    zwqp: '暂无切片，可前往模型库添加切片',
    zwsb: '暂无设备，可前往工作台添加设备',
    sfqrztdy: '是否确认暂停当前打印？',
    sfqrzzdy: '是否确认终止当前打印？',
    cg: '层高',
    XYbc: 'XY补偿',
    Zbc: 'Z补偿',
    kjc: '抗锯齿',
    zdsczc: '自动生成支撑',
    zdscsyzc: '自动生成所有支撑',
    xzqp: '选择切片',
  },


  historyTask: {
    breadcrumb_home: '首页',
    breadcrumb_gzt: '工作台',
    breadcrumb_lsrw: '历史任务',
    breadcrumb_rwxq: '任务详情',

    rwmc: '任务名称',
    dyzz: '打印终止',
    zzsj: '终止时间',
    sjzl: '数据总览',
    fdmqpwj: 'FDM切片文件',
    fen: '份',
    gghqpwj: '光固化切片文件',
    device: '设备',
    tai: '台',
    dyzsj: '打印总时长',
    fz: '分钟',
    fdmhcyl: 'FDM耗材用量',
    gghhcyl: '光固化耗材用量',
    qpwj: '切片文件',
    qpwj: '切片文件',

    // 打印历史记录
    dyrw: '打印任务',
    sbsl: '设备数量',
    qpwjfs: '切片文件份数',
    ddy: '待打印',
    jxz: '进行中',
    ywc: '已完成',
    yzz: '已终止',
    zt: '暂停',

    dysc: '打印时长',
    hcsyl: '耗材使用量',
    ck: '查看',
    tjrw: '添加任务',
    zwdyrw: '暂无打印任务',
  },
  vip: {
    hytc: '会员套餐',
    yuan: '元',
    month: '月',
    ljkt: '立即开通',
    hyqy: '会员权益',

    hl3Dmx: '海量3D模型',
    yqp: '云切片',
    yckzdy: '远程控制打印',
    ssjkdy: '实时监控打印',

    ycckj: '云储存空间',
    mxplxz: '模型批量下载',
    dysphf: '打印视频回放',
    yssy: '延时摄影',

    jqdytq: '集群打印特权',
    qwDIYjm: '趣味DIY建模',
    fbczsp: '发布创作视频',
    kjkrzk: '空间扩容折扣',

    zskf: '专属客服',
    VIPjm: 'VIP模型',
    VIPrw: 'VIP任务',
    dmxqp: '多模型切片',

    ktsm: '开通说明',
    ktsm_text01: '1.您可以在会员中心查看权益内容，详细会员服务条款请查看',
    ktsm_text02: '《会员服务协议》',
    ktsm_text03: '。\n2.购买完成后10分钟内会员服务的服务期到账，因网络原因延迟的，请静候5-10分钟后重新查看或重启App查看。若仍未到账或无法使用，请联系在线客服。\n3.订阅周期可以为月度、季度和年度，具体以您自行选择开通的类型为准。通过特殊活动开通自动续费服务的，相应的扣费时间可能有所不同请以活动页面提示为准。',
    gmvip: '购买会员',
    content: '内容',
    qy: '权益',
    price: '价格',
    x20xqy: '享20项权益',
    zffs: '支付方式',
    yfje: '应付金额',

    ktvipcg: '恭喜您 开通会员成功',
  },
  // 存储空间
  storage: {
    gmkjtc: '购买空间套餐',
    yuan: '元',
    yj: '原价',
    qxzkjtc: '请选择空间套餐',
    yxq01: '有效期',
    yxq02: '月',
  },
  pay: {
    zffs: '支付方式',
    alipay: '支付宝支付',
    wxpay: '微信支付',
    yfje: '应付金额',
    yuan: '元',
    ywczf: '已完成支付',
    nhwwczf: '您还未完成支付',
    syt: '收银台',
    ddtjcgcfk: '订单提交成功，只差付款了~',
    ddzfjsts01: '请您在',
    ddzfjsts01: '内完成支付，否则订单将自动取消',
    shxx: '收货信息',
    spmc: '商品名称',
    dgd: '等更多',
    gmsj: '购买时间',
    zffs: '支付方式',
    ddje: '订单金额',
    ddxq: '订单详情',
    fkcg: '付款成功',
    zfwchky01: '支付完成后，您可以',
    zfwchky02: '查看订单详情',
    zfwc: '支付完成',
    zfts_tips: '您正在网站外支付,请支付完成后回到当前页确认',
    cxxzzffs: '重新选择支付方式',
    pay: '支付',
    wwczfqzf: '未完成支付，请支付',
    jyygb: '交易已关闭',
    ckzfqk: '查询支付情况',
  },
  feedback: {
    wdfk: '我的反馈',
    fknr: '反馈内容',
    clyj: '处理意见',
    tjsj: '提交时间',
    type: '类型',
  },


  shoppingCart: {
    gwc: '购物车',
    yxsp: '已选商品',
    bhyf: '不含运费',
    js: '结算',
    spxx: '商品信息',
    sl: '数量',
    je: '金额',
    cz: '操作',
    ydsclb: '移入收藏夹',
    qdyscsp: '确定要删除商品吗?',
    qxzyjssp: '请选择要结算商品',
    cgjrgwc: '成功加入购物车',
    zgg: '再逛逛',
    qjs: '去结算',
    sc: '商城',
    ndgwcskd: '您的购物车还是空的,赶紧去',
    ggb: '逛逛吧',
    qxzyscsp: '请选择要删除商品',
    qxzyrscjsp: '请选择要移入收藏夹的商品',
    qx: '全选',
  },


  footer: {
    gywm: '关于我们',
    gsjs: '公司介绍',
    jrwm: '加入我们',
    zcfw: '支持服务',
    bzzx: '帮助中心',
    xszn: '新手指南',
    yjfk: '意见反馈',
    fwxy: '服务声明',
    yhxy: '用户协议',
    yszc: '隐私政策',
    bqsm: '版权声明',
    hyfwxy: '会员服务协议',
    gsjj: '公司简介',
    yxfw_cjmh: '用心服务·成就美好',
    feedback_tips01: '您可以通过访问',
    feedback_tips02: '「帮助中心」',
    feedback_tips03: '查看热门问题、热门意见反馈',
    fklx: '反馈类型',
    content: '内容',
    qsrfknr: '请输入反馈内容',
    zsxz: '字数限制在10~500字',
    tjfk: '提交反馈',
    wdfk: '我的反馈',
    qxzfklx: '请选择反馈类型',
    qsr10gzfnr: '请输入至少10个字符的反馈内容',
    ckgd: '查看更多',
  },
  ...zhLocale
}
export default cn
