
//接口名称
var api = {
  'sendCaptcha': '/app/common/sendCaptcha', // 发送登录验证码
  'loginByCaptcha': '/app/auth/loginByCaptcha', // 验证码登录
  'loginByUsername': '/app/auth/loginByUsername', // 密码登录
  'logout': '/app/auth/logout', // 密码登录
  'getUserInfo': '/app/common/getUserInfo', // 获取登录用户信息
  'dictList': '/system/dict/data/list', // 字典接口
  'upload': '/app/common/uploadToServer', // 上传文件
  'generateQrCode': '/pc/scanLogin/generateQrCode', // 获取二维码
  'polling': '/pc/scanLogin/polling', // pc端轮询获取token
  'loginByOauth': '/app/auth/loginByOauth', // 三方登录
  'getWXAuthToken': '/app/auth/getWXAuthToken', // 微信登录解析code
  'getQQAuthToken': '/app/auth/getQQAuthToken', // qq登录解析code
  'getWBAuthToken': '/app/auth/getWBAuthToken', // 微博登录解析code

  'getListByType': '/app/advertisement/getListByType', // 获取指定bannerType的广告列表
  'getModelList': '/app/model/getModelList', // 获取模型列表
  'getModel': '/app/model/getModel', // 获取模型列表
  'addModel': '/app/model/addModel', // 新增模型
  'addUserCollect': '/app/behavior/addUserCollect', // 新增 商铺/商品/模型 收藏接口
  'updateUserCollect': '/app/behavior/updateUserCollect', // 取消 商铺/商品/模型 收藏接口
  'getRealPicList': '/app/modelRealPic/getRealPicList', // 获取模型实拍效果列表
  'addRealPic': '/app/modelRealPic/addRealPic', // 获取模型实拍效果列表
  'getCommentList': '/app/modelComment/getCommentList', // 获取评论列表
  'addModelComment': '/app/modelComment/addModelComment', // 新增评论

  'columnList': '/app/column/columnList', // app查询专栏
  'columnDetails': '/app/column/columnDetails', // 获取专栏详情

  
  //共用
  uploadToServer:'/app/common/uploadToServer',//上传图片
  //商城接口
  appClassList:'/app/shop/appClassList',//商品分类
  appCommodityList:'/app/shop/appCommodityList',//商品列表
  shopCommodityList:'/app/shop/shopCommodityList',//商品列表-店铺
  recommendClassList:'/app/shop/recommendClassList',//产品库首页
  // recommendClassList:'/app/shop/recommendClassList',//产品库首页
  appCommodityDetail:'/app/shop/appCommodityDetail',//商品详情
  getRegin:'/app/shop/getRegin',//商城获取地址
  getFreightPrice:'/app/shop/getFreightPrice',//根据地址获取邮费
 
  commoditySpecification:'/app/shop/commoditySpecification',//获取规格详情
  shopDetail:'/app/shop/shopDetail',//商城商铺详情接口
  getCommodityReputations:'/app/behavior/getCommodityReputations',//获取商品评论
  getShoppingCartList:'/app/shop/getShoppingCartList',//购物车列表
  addShoppingCart:'/app/shop/addShoppingCart',//加入购物车
  updateCommodity:'/app/shop/updateCommodity',//修改购物车
  getShoppingCartCount:'/app/shop/getShoppingCartCount',//购物车数量

  addUserCollectByShoppIngCart:'/app/behavior/addUserCollectByShoppIngCart',//购物车加入收藏夹
  insertTemporaryOrder:'/app/order/insertTemporaryOrder',//添加订单结算
  temporaryOrderList:'/app/order/temporaryOrderList',//预览订单结算
  deleteTemporaryOrder:'/app/order/deleteTemporaryOrder',//删除订单结算
  getUserAddress:'/app/shop/getUserAddress',//获取用户地址
  addUserAddress:'/app/shop/addUserAddress',//编辑-添加收获地址
  updateUserAddress:'/app/shop/updateUserAddress',//修改用户地址
  insertUserOrder:'/app/order/insertUserOrder',//提交订单
  payOrder:'/zyy/payMent/pay/payOrder',//支付订单

  //订单数据
  getHeaderTotal:'/app/order/getHeaderTotal',//获取订单数量数据
  getUserOrderList:'/app/order/getPcUserOrderList',//查询订单列表
  updateOrderStatu:'/app/order/updateOrderStatu',//操作订单
  getOrderDetail:'/app/order/getOrderDetail',//查询订单详情
  insertAfterSale:'/app/afterSale/insertAfterSale',//申请售后
  getExpressDetail:'/app/order/getExpressDetail',//获取订单物流信息
  addUserReputations:'/app/behavior/addUserReputations',//发表评论
  getAfterList:'/app/afterSale/getAfterList',//售后列表
  getAfterSalaDeatil:'/app/afterSale/getAfterSalaDeatil',//售后详情
  insertExpressage:'/app/afterSale/insertExpressage',//客户寄回
  getExpressCompanyList:'/app/afterSale/getExpressCompanyList',
  updateOrderDetail:'/app/order/updateOrderDetail',//修改订单
  updateAfterSaleStatu:'/app/afterSale/updateAfterSaleStatu',//撤销售后


  enjoyComment:'/app/modelComment/enjoyComment',//点赞评论
  cancelEnjoyComment:'/app/modelComment/cancelEnjoyComment',//取消点赞评论
    getProtocol:'/app/common/getProtocol',//获取协议
  
  getCopyrightList: '/app/common/getCopyrightList', // 获取模型版权声明
  addFeedback:'/app/personal/addFeedback',//用户添加意见反馈
  modifyPersonalInfo:'/app/personal/modifyPersonalInfo',//修改个人信息
  verifyCaptcha:'/app/common/verifyCaptcha',//验证验证码
  resetPwd:'/app/personal/resetPwd',//验证码重置密码
  cancelAccount: '/app/personal/cancelAccount',//注销账号
  
  unbindOtherAppInfo:'/app/personal/unbindOtherAppInfo',//解绑三方登录信息
  bindingMobile:'/app/personal/bindingMobile',//绑定手机号
  balanceLogList:'/app/personal/getUserBalanceLogList',//绑定手机号
  sendTextMail:'/app/common/sendTextMail',//发送邮件验证码
  sendMailLoginCaptcha:'/app/common/sendMailLoginCaptcha',//发送邮件验证码--登录
  bindingEmail:'/app/personal/bindingEmail',//绑定邮箱
  getUserCollect:'/app/behavior/getUserCollect',//用户 商铺/商品/模型 收藏列表 接口

  getDownloadModelList:'/app/personal/getDownloadModelList',//已下载模型列表
  deleteDownloadModelFile:'/app/personal/deleteDownloadModelFile',//删除下载记录
  getUploadModelList:'/app/personal/getUploadModelList',//已上传模型列表
  deleteUploadModelFile:'/app/personal/deleteUploadModelFile',//删除上传模型记录
  deleteSelfModel:'/app/model/deleteSelfModel',//删除上传模型
  getSliceTaskList:'/app/sliceTask/getSliceTaskList',//我的切片列表
  getUserReputations:'/app/behavior/getUserReputations',//获取当前用户评价列表
  helpList:'/zyy/serverModule/list',//pc端 获取支持服务
  deleteSliceTask:'/app/sliceTask/deleteSliceTask',//删除切片任务
  cancelSliceTask:'/app/sliceTask/cancel',//取消切片任务

  getUserPrinterList: '/app/workbench/getUserPrinterList', // 获取用户打印机列表
  getUserPrinterStatus: '/app/workbench/getUserPrinterStatus', // 获取用户所有打印机状态
  getPrinterInfo: '/app/workbench/getPrinterInfo', // 获取打印机详情
  getPrinterHistoricalTaskList: '/app/workbench/getPrinterHistoricalTaskList', // 获取打印机历史打印任务
  getSlicedFileList: '/app/printTask/getSlicedFileList', // 获取已切片文件列表
  createPrintTask: '/app/printTask/createPrintTask', // 创建打印任务
  startChildrenPrintTask: '/app/printTask/startChildrenPrintTask', // 开始打印子任务
  getPrinterTaskDetail: '/app/printTask/getPrinterTaskDetail', // 获取打印任务详情
  printTaskPause: '/app/printTask/pause', // 暂停打印任务
  printTaskContinue: '/app/printTask/continue', // 恢复继续打印任务
  printTaskCancel: '/app/printTask/cancel', // 取消打印任务

  getRechargePackageList: '/app/userConsumption/getRechargePackageList', // 获取充值套餐列表
  createPayOrder: '/app/userConsumption/createPayOrder', // 创建消费订单
  consumptionPay: '/app/userConsumption/consumptionPay', // 支付接口
  buyModel: '/app/buyModel/buyModel', // 购买模型
  getMemberPackageList: '/app/userConsumption/getMemberPackageList', // 获取会员套餐列表
  getStorageSpacePackageList: '/app/userConsumption/getStorageSpacePackageList', // 获取存储空间套餐列表
  downloadFromServer: '/app/common/downloadFromServer', // 下载文件

  getUserPrintTaskList: '/app/workbench/getUserPrintTaskList', // 获取用户历史打印任务
  getPrintTaskDetail: '/app/workbench/getPrintTaskDetail', // 获取用户历史打印详情
  getSliceTask: '/app/sliceTask/getSliceTask', // 获取切片任务详情

  loginByOauthBindMobile: '/app/auth/loginByOauthBindMobile', // 绑定手机号

  getProtocol: '/app/common/getProtocol', // 获取用户协议

  modelUploadToCloud: '/app/personal/modelUploadToCloud', // 本地模型文件上传到云端

  getFeedbackList: '/app/personal/getFeedbackList', // 获取用户意见反馈列表
  getSliceTaskStatus: '/app/sliceTask/getSliceTaskStatus', // 获取切片任务状态及进度

  getPolicy: '/app/common/getUploadInfo', // 获取oss签名
  modelUpload: '/app/common/modelUpload', // 记录模型文件信息

  getModelFileUrl: '/app/model/getModelFileUrl', // 获取模型文件下载链接

  deleteModelComment: '/app/modelComment/deleteModelComment', // 删除模型/实拍评论
  checkRemainStorageSpace: '/app/common/checkRemainStorageSpace', // 判断用户剩余存储空间是否足够
  checkModelOptimizeStatus: '/app/model/checkModelOptimizeStatus', // 检查模型是否优化完成

  deleteFiles: '/app/common/deleteFiles', // 删除模型上传文件

  getFacebookAuthAppToken: '/app/auth/getFacebookAuthAppToken', // Facebook登录
  getGoogleAuthAppToken: '/app/auth/getGoogleAuthAppToken', // Google登录
  getGoogleAccessToken: '/app/auth/getGoogleAccessToken', // Google解析AccessToken

  paypal: '/zyy/payMent/pay/paypal', // paypal支付
  captureOrder: '/zyy/payMent/pay/paypal/captureOrder', // paypal支付成功回调接口
  getNewSocketKey: '/app/personal/getNewSocketKey', // 获取新的socket连接秘钥
  getOrderStatus: '/app/userConsumption/getOrderStatus', // 获取订单支付状态

  enjoyRealPic: '/app/modelRealPic/enjoyRealPic', // 点赞实拍
  cancelEnjoyRealPic: '/app/modelRealPic/cancelEnjoyRealPic', // 取消点赞实拍

  getReplyInformation: '/app/afterSale/getReplyInformation', // 重新申请售后获取价格
  resetPwdByOld: '/app/personal/resetPwdByOld', // 通过原密码修改密码

  deleteTripartiteSlicedFile: '/app/printTask/deleteTripartiteSlicedFile', // 删除软件切片任务
}

export default api;







