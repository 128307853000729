<template>
  <div class="UploadImg">
    <el-upload
      :class="{'avatar-uploader': !showCustomUpload}"
      :action="baseUploadURL"
      :show-file-list="showFileList"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-progress="onUploadProgress"
      :on-success="onUploadSuccess"
      :on-error="onUploadError"
      :headers="{ Authorization: token, 'Accept-Language': acceptLanguage}"
      :accept="accept"
    >
      <slot v-show="showCustomUpload" name="uploader-slot"></slot>
      <div
        v-show="!showCustomUpload"
        class="uploader-icon"
        :style="`width: ${width}; height: ${height};`"
      >
        <div><i class="el-icon-plus"></i></div>
        <div class="f12">{{ labelText }}</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import api from "@/api/api";
import { mapState } from "vuex";

let _that;
export default {
  name: "UploadImg",
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    acceptLanguage() {
      var lang = localStorage.getItem("lang");
      var hLang = 'zh_CN';
      //en_US 英文 CN 中文
      if(lang == 'en'){
          hLang = 'en-US';
        }else{
          hLang = 'zh_CN';
        }
      return hLang;
    }
  },
  props: {
    // 是否显示默认文件列表
    showFileList: {
      type: Boolean,
      default: false,
    },

    // 文件列表 showFileList 为 true是生效
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    // 文件列表 showFileList 为 true是生效
    accept: {
      type: String,
      default: ".jpg, .jpeg, .png, .gif",
    },

    // 展示自定义上传图片按钮, 为true时下面的属性将不生效
    showCustomUpload: {
      type: Boolean,
      default: false,
    },

    // 上传组件文字
    labelText: {
      type: String,
      default: "add image",
    },

    // 上传组件宽度
    width: {
      type: String,
      default: "100px",
    },

    // 上传组件高度
    height: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return {
      baseUploadURL: this.uploadUrl,
      uploadApi: api.upload,
      loading: null,
    };
  },
  created() {
    _that = this;
  },
  mounted() {},
  methods: {
    // 上传图片前
    beforeUpload(file) {
      let fileType = file.type.split('/')[1];
      const isJPG = this.accept.indexOf(fileType) != -1;
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        // this.$message.error(this.$t('common.imgFormatErr') + this.accept);
        this.utils.reminderMsg(this.$t('common.imgFormatErr') + this.accept, 1);
      }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      if (isJPG) this.$emit("beforeUpload", file);
      return isJPG;
    },
    // 上传图片成功
    onUploadSuccess(res, file) {
      this.loading.close();
      if (res.code == 200) {
        this.$emit("onUploadSuccess", res);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传图片失败
    onUploadError(err, file) {
      this.loading.close();
    },
    // 上传图片中
    onUploadProgress(event, file) {
      this.loading = this.$loading({
        lock: true,
        text: this.$t("common.zzsc"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.uploader-icon {
  font-size: 30px;
  color: #8c939d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
