export default {
  state: {
    // 是否初始化 socket
    isInitSocket: false,
    // 接收到的消息
    socketReceiveMsg: '',
    // 发送 socket 消息
    socketSendMsg: '',

    // 设备绑定成功/失败消息
    bindDeviceMsg: '',
    // 设备暂停打印成功/失败消息
    pausePrintMsg: '',
    // 设备继续打印成功/失败消息
    continuePrintMsg: '',
    // 设备终止打印成功/失败消息
    cancelPrintMsg: '',
  },
  getters: {
  },
  mutations: {
    SET_IS_INIT_SOCKET(state, data) {
      // 是否初始化 socket
      state.isInitSocket = data;
    },
    SET_SOCKET_RECEIVE_MSG(state, data) {
      // socket 接收的消息
      state.socketReceiveMsg = data;
    },
    SET_SOCKET_SEND_MSG(state, data) {
      // socket 发送的消息
      state.socketSendMsg = data;
    },
    SET_BIND_DEVICE_MSG(state, data) {
      // 设备绑定成功/失败消息
      state.bindDeviceMsg = data;
    },
    SET_PAUSE_PRINT_MSG(state, data) {
      // 设备暂停打印成功/失败消息
      state.pausePrintMsg = data;
    },
    SET_CONTINUE_PRINT_MSG(state, data) {
      // 设备继续打印成功/失败消息
      state.continuePrintMsg = data;
    },
    SET_CANCEL_PRINT_MSG(state, data) {
      // 设备终止打印成功/失败消息
      state.cancelPrintMsg = data;
    },
  },
  actions: {
    // 初始化 socket
    initSocket({ commit }, data) {
      commit('SET_IS_INIT_SOCKET', data);
    },
    // socket 接收的消息
    socketReceiveMsg({ commit }, data) {
      commit('SET_SOCKET_RECEIVE_MSG', data);
    },
    // socket 发送的消息
    sendSocketMsg({ commit }, data) {
      commit('SET_SOCKET_SEND_MSG', data);
    },

    // 设备绑定成功/失败消息
    bindDeviceMsg({ commit }, data) {
      commit('SET_BIND_DEVICE_MSG', data);
    },
    // 设备暂停打印成功/失败消息
    pausePrintMsg({ commit }, data) {
      commit('SET_PAUSE_PRINT_MSG', data);
    },
    // 设备继续打印成功/失败消息
    continuePrintMsg({ commit }, data) {
      commit('SET_CONTINUE_PRINT_MSG', data);
    },
    // 设备终止打印成功/失败消息
    cancelPrintMsg({ commit }, data) {
      commit('SET_CANCEL_PRINT_MSG', data);
    },
  },
  namespaced: true,
}
