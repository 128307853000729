import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

// 引入国际语言包
import i18n from "../i18n/i18n";

import 'element-ui/lib/theme-chalk/index.css';

// 引入公用样式
import '@/assets/css/index.css'
import '@/assets/css/style.css'

// 引入api
import api from './api/api.js'

// 引入异步请求文件
import request from './utils/request.js'

// 引入公用方法
import utils from './utils/utils.js';

import QRcode from './utils/QRcode'

import '@/assets/js/jquery-1.9.1.min.js';

import md5 from 'js-md5';


import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

Vue.prototype.api = api
Vue.prototype.baseURL = request.baseURL
Vue.prototype.http = request.request
Vue.prototype.utils = utils
Vue.prototype.uploadUrl = request.baseUploadURL + api.uploadToServer;
Vue.prototype.apiWebSocketUrl = request.apiWebSocketUrl;
Vue.prototype.$md5 = md5;


Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(QRcode);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
