<template>
  <div class="header-view flex-c-c">
    <!-- 顶部栏 -->
    <div class="flex-c-c">
      <div class="flex-c-b" style="width: 1200px">
        <!-- 上传模型&工作台 -->

        <div class="flex-c">
          <!-- 上传模型 -->
          <div class="upload-view btn flex-c-c mr20" @click="openUploadModel">
            <img
              class="upload-logo mr10"
              src="../assets/images/upload.png"
              alt=""
            />
            <div class="upload-text">{{ $t("header.uploadModel") }}</div>
          </div>

          <!-- 工作台 -->
          <div
            @click="toModel('/workbenches')"
            class="staging-view btn flex-c-c"
          >
            <img
              class="staging-logo mr10"
              src="../assets/images/staging.png"
              alt=""
            />
            <div class="staging-text">{{ $t("header.workbench") }}</div>
          </div>
        </div>

        <!-- 上传文件&工作台 end -->

        <!-- 语言&购物车&会员&登录&注册 -->

        <div class="flex-c">
          <!-- 语言 -->
          <el-dropdown @command="setLanguage" :tabindex="9999">
            <div class="flex-c mr30 el-dropdown-link">
              <img
                class="language-logo mr10"
                src="../assets/images/language.png"
                alt=""
              />
              <div class="language-text">
                {{ lang == "cn" ? "简体中文" : "English"
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="cn" class="language-text"
                >简体中文</el-dropdown-item
              >
              <el-dropdown-item command="en" class="language-text"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 购物车 -->
          <div @click="toShoppingCart" class="flex-c mr20" style="cursor: pointer;">
            <img
              class="shopping-cart-logo mr10"
              src="../assets/images/shopping-cart.png"
              alt=""
            />
            <div class="shopping-cart-text">{{$t('shoppingCart.gwc')}}（{{ carNum }}）</div>
          </div>

          <!-- 会员 未登录或未开通 -->
          <div v-if="!token || !!userInfo && userInfo.isMember != 1" class="flex-c" @click="openBuyMemberVisible" style="cursor: pointer;">
            <img class="vip-logo" src="../assets/images/vip-logo.png" alt="" />
            <div class="vip-bg-view mr10 flex-c-c">{{$t('modelDetail.ktvip')}}</div>
          </div>

          <!-- 会员 已登录已未开通 -->
          <div v-else class="flex-c mr30" @click="$router.push({path: '/userBuyVip'})">
            <img class="vip-logo" src="../assets/images/vip-logo.png" alt="" />
            <!-- 到期 -->
            <div class="out-time mr10">{{!!userInfo ? userInfo.memberExpiryTime : ''}}{{$t('header.becomeDue')}}</div>
            <!-- 续费 -->
            <div class="flex-c-c renew">{{$t('header.renew')}}</div>
          </div>

          <!-- 未登录 -->
          <div v-if="!token" class="flex-c">
            <!-- 注册 -->
            <!-- <div
              class="flex-c-c register-btn mr10"
              @click="registerVisible = true"
            >
              注册
            </div> -->

            <!-- 登录 -->
            <div class="flex-c-c login-btn" @click="handleHeadreLogin">
              {{$t('header.login')}}
            </div>
          </div>

          <!-- 已登录 -->
          <el-dropdown v-else trigger="click" :tabindex="9999" @command="handleCommand">
            <span class="el-dropdown-link">
              <div class="user-box flex-c">
                <el-image
                  class="avatar-img mr10"
                  fit="cover"
                  :src="!!userInfo && !!userInfo.avatar ? userInfo.avatar : defaultAvatar"
                />
                <!-- <img class="avatar-img mr10" :src="!!userInfo && !!userInfo.avatar ? userInfo.avatar : defaultAvatar" alt=""> -->
                <div class="flex-c">
                  <div>{{ !!userInfo ? userInfo.nickName : "" }}</div>
                  <i class="el-icon-caret-bottom ml10"></i>
                </div>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="userInfo">
                <!-- 个人中心 -->
                {{ $t("login.grzx") }}
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                <!-- 退出登录 -->
                {{ $t("login.tcdl") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 语言&购物车&会员&登录&注册 end -->
      </div>
    </div>
    <!-- 顶部栏 end -->

    <el-divider></el-divider>

    <!-- 导航栏 -->
    <div class="flex-c-b" style="width: 1200px; padding: 10px 0;">
      <!-- 左边导航栏 -->

      <div class="flex-c navBox">
        <img
          @click="toModel('/')"
          class="logo mr90 btn"
          src="../assets/images/logo.png"
          alt=""
        />
        <el-menu
          :default-active="$route.path"
          active-text-color="red"
          router
          mode="horizontal"
          @select="selectMenu"
        >
          <el-menu-item
            v-for="route in routes"
            :key="route.path"
            :index="route.path"
            :class="($route.path == '/modelLib' && route.path == '/modelLib') ||
                   (route.path != '/modelLib' &&  ( $route.path.indexOf( route.path ) > -1   || route.field.indexOf( $route.path ) > -1 ))
                    ? 'is-active' : ''"
            >{{ route.name }}</el-menu-item
          >
        </el-menu>
      </div>

      <!-- 右边搜索框 -->
      <div class="search-view-bg">
        <!-- <img class="search-logo mr10" src="../assets/images/search-logo.png" alt="" @click="handleSearch"/> -->
        <el-select v-model="searchSelect" :class="lang" :placeholder="$t('common.qxz')">
          <!-- 模型库 -->
          <el-option :label="$t('header.modelBase')" :value="0"></el-option>
          <!-- 模型专栏 -->
          <el-option :label="$t('header.modelColumn')" :value="1"></el-option>
          <!-- 产品库 -->
          <el-option :label="$t('header.productLibrary')" :value="2"></el-option>
        </el-select>
        <el-divider direction="vertical"></el-divider>
        <!-- 搜索你感兴趣的内容 -->
        <input class="input ml5" type="text" v-model="search" @keyup.enter="handleSearch" :placeholder="$t('header.searchPlaceholder')"/>
      </div>
    </div>
    <!-- 导航栏 end -->

    <!-- 上传模型弹框 -->
    <el-dialog
      :title="$t('modelPublish.title')"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="uploadModelVisible"
      width="40%"
      @close="uploadModelClose">

      <el-divider />
      <div style="padding: 0 20px 30px 20px">
        <!-- 模型来源 -->
        <div>
          <div class="model-title mb20">
            <!-- 模型来源 -->
            <span class="c_red">*</span> {{ $t("modelPublish.mxly") }}
          </div>
          <el-radio-group
            v-model="modelPublishForm.modelSource"
            size="medium"
            class="model-source"
          >
            <!-- 原创模型 -->
            <el-radio-button label="1">{{$t("modelPublish.ycmx")}}</el-radio-button>
            <!-- 分享模型 -->
            <el-radio-button label="2" class="ml20">{{$t("modelPublish.fxmx")}}</el-radio-button>
            <!-- 二次创作模型 -->
            <el-radio-button label="3" class="ml20">{{$t("modelPublish.ecczmx")}}</el-radio-button>
          </el-radio-group>
          <div class="mt20 flex-c-b model-bor">
            <!-- 版权声明 -->
            <div class="f14">
              <span class="c_red">* </span> {{ $t("modelPublish.bqsm") }}
            </div>
            <div @click="showCopyrightList = true">
              <!-- 请选择知识共享许可协议 -->
              {{ !!modelCopyrightName ? modelCopyrightName : $t("modelPublish.xzgxxy") }}
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <el-divider />
        </div>
        <!-- 模型来源 end-->
        <div v-show="modelPublishForm.modelSource == 1">
          <!-- 定价 -->
          <div>
            <div class="model-title mb20">
              <span class="c_red">*</span> {{ $t("modelPublish.dj") }}
            </div>
            <el-radio-group size="medium" v-model="modelPublishForm.modelChargeStatu" fill="#FFEBEB" text-color="#C32D30">
              <el-radio :label="0" fill="#FFEBEB" text-color="#C32D30">
                <!-- 收费 -->
                {{ $t("modelPublish.sf") }}
              </el-radio>
              <el-radio :label="1" fill="#FFEBEB" text-color="#C32D30">
                <!-- 免费 -->
                {{ $t("modelPublish.mf") }}
              </el-radio>
            </el-radio-group>
            <div v-show="modelPublishForm.modelChargeStatu == 0" class="mt20">
              <el-input v-model="modelPublishForm.modelPrice" @input="inputZybCallback" :placeholder="$t('modelPublish.srzybjg')"></el-input>
            </div>
            <el-divider />
          </div>
          <!-- 定价 end -->
          <!-- 原创证明 -->
          <div>
            <div class="model-title mb20">
              <!-- 原创证明 -->
              <span class="c_red">*</span> {{ $t("modelPublish.yczm") }}
              <span class="f16 c_999">{{ $t("modelPublish.yczm_tips") }}</span>
            </div>
            <div class="mt20">
              <div class="model-proof-list flex-c">
                <template v-for="(item, index) in modelProofList">
                  <div :key="index" class="model-proof-item">
                    <div class="del-box">
                      <i class="el-icon-delete del-icon" @click="delModelProofImg(index)"></i>
                    </div>
                    <el-image :src="item.url" fit="cover" :style=" index == modelProofList.length - 1 ? 'margin-right: 0;' : ''"></el-image>
                  </div>
                </template>
                <UploadImg v-show="modelProofList.length < 9"
                          :labelText="$t('modelPublish.sczm')"
                          @onUploadSuccess="handleModelProofSuccess"
                          width="130px"
                          height="130px" >
                </UploadImg>
              </div>
            </div>
            <el-divider />
          </div>
          <!-- 原创证明 end -->
        </div>
        <!-- 上传模型 -->
        <div>
          <div class="model-title mb20">
            <!--上传模型 -->
            <span class="c_red">*</span> {{ $t("modelPublish.scmx") }}
          </div>
          <div class="mode-file-list">
            <template v-for="(item, index) in modelFilesList">
              <div :key="index + item['fileId']" class="mode-file-item flex-c">
                <div v-show="!!item.pathThumb" class="file-thumb">
                   <div class="del-box">
                    <i class="el-icon-delete del-icon" @click="delModelFilesImg(index)"></i>
                  </div>
                  <el-image style="width: 80px; height: 80px; border-radius: 4px;" :src="item.pathThumb" fit="cover"></el-image>
                </div>
                <UploadImg v-show="!item.pathThumb"
                           :labelText="$t('common.addImg')"
                           @onUploadSuccess="handleModelFilesImgSuccess($event, item)"
                           width="80px" height="80px">
                </UploadImg>
                <div class="mode-file-info ml20">
                  <div class="flex-c-b" style="width: 100%;">
                    <div class="file-name" style="flex: 1;">{{item.fileName}}</div>
                    <div class="ml10">
                      <span v-if="!item['fileId'] && !isUploadModelFilesErr">{{$t('common.zzsc')}}</span>
                      <span v-if="!!item['fileId']">{{$t('modelPublish.scwc')}}</span>
                      <span v-if="isUploadModelFilesErr">{{$t('modelPublish.scsb')}}</span>
                      <i v-show="!!item['fileId'] || isUploadModelFilesErr" class="el-icon-delete del-icon ml10" @click="delModelFile(index, item)"></i>
                    </div>
                  </div>
                  <div style="width: 100%;">
                    <el-progress :percentage="item['percent']" :stroke-width="3" color="#C32D30" :show-text="false"></el-progress>
                    <div class="model-file-size">{{filesize(item['modelFileProgress'])}}/{{filesize(item['size'])}}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="mt20 upload-box">
            <el-upload
              action
              :show-file-list="false"
              :http-request="uploadModelFile"
              :before-upload="(file) => modelFileBeforeUpload(file)"
              :on-progress="(res, file) => modelFileUploadProgress(res, file)"
              :on-success="(res, file) => handleModelFileSuccess(res, file)"
              :on-error="triggerUploadError"
              :headers="{ Authorization: token }"
              :data="uploadFileParamData"
              :accept="acceptFile"
            >
              <div class="flex-c-c" style="width: 100%; height: 58px;">
                <i class="el-icon-plus upload-icon"></i>
                <div class="upload-text1">{{$t('modelPublish.add')}}</div>
              </div>
            </el-upload>
          </div>
          <div class="f14 c_666 mt20">
            <!-- 每次最多可上传500MB大小的文件-->
            {{$t('modelPublish.file_tips01')}}
          </div>
          <div class="f14 c_666 mt10">
            <!-- 目前支持的模型文件格式: .stl,.obj,.ply,.3mf-->
            {{$t('modelPublish.file_tips02')}}
            <span>{{acceptFile}}</span>
          </div>
          <el-divider />
        </div>
        <!-- 上传模型 end -->
        <!-- 模型名称 -->
        <div>
          <div class="model-title mb20">
            <span class="c_red">*</span> {{$t('modelPublish.mxmc')}}
          </div>
          <div class="mt20 flex-c-b">
            <el-input
              type="text"
              v-model="modelPublishForm.modelName"
              :placeholder="$t('modelPublish.mxmc_placeholder')"
              maxlength="60"
              show-word-limit
            ></el-input>
          </div>
          <el-divider />
        </div>
        <!-- 模型名称 end -->
        <!-- 模型类别 -->
        <div>
          <div class="model-title mb20">
            <span class="c_red">*</span> {{$t('modelPublish.mxlb')}}
          </div>
          <div class="mt20">
            <!-- <el-input
              v-model="modelPublishForm.modelClass"
              placeholder="请选择模型类别"
              disabled
              suffix-icon="el-icon-arrow-right"
            ></el-input> -->
            <el-select v-model="modelPublishForm.modelClass" :placeholder="$t('modelPublish.mxlb_placeholder')" style="width: 100%;">
              <el-option
                v-for="item in modelClass"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue">
              </el-option>
            </el-select>
          </div>
          <el-divider />
        </div>
        <!-- 模型类别 end -->
        <!-- 隐私设定 -->
        <div>
          <div class="model-title mb20">
            <span class="c_red">*</span> {{$t('modelPublish.yssz')}}
          </div>
          <el-radio-group size="medium"
            v-model="modelPublishForm.modelPrivacy" fill="#FFEBEB" text-color="#C32D30">
            <el-radio :label="0" fill="#FFEBEB" text-color="#C32D30">{{$t('modelPublish.gk')}}</el-radio>
            <el-radio :label="1" fill="#FFEBEB" text-color="#C32D30">{{$t('modelPublish.zy')}}</el-radio>
          </el-radio-group>
          <el-divider />
        </div>
        <!-- 隐私设定 end -->
        <!-- 图集 -->
        <div>
          <div class="model-title mb20">
            <span class="c_red">*</span> {{$t('modelPublish.img_list')}}
            <span class="f16 c_999">{{$t('modelPublish.img_list_tips')}}</span>
          </div>
          <div class="mt20">
            <div class="model-proof-list flex-c">
              <template v-for="(item, index) in modelDisplayList">
                <div :key="index" class="model-proof-item">
                  <div class="del-box">
                    <i class="el-icon-delete del-icon" @click="delModelDisplay(index)"></i>
                  </div>
                  <el-image :src="item.url" fit="cover" :style=" index == modelDisplayList.length - 1 ? 'margin-right: 0;' : ''"></el-image>
                </div>
              </template>
              <UploadImg v-show="modelDisplayList.length < 10"
                         :labelText="$t('modelPublish.sctj')"
                         @onUploadSuccess="handleModelDisplayList"
                         width="130px"
                         height="130px">
              </UploadImg>
            </div>
          </div>
          <el-divider />
        </div>
        <!-- 图集 end -->
        <!-- 描述 -->
        <div>
          <div class="model-title mb20">{{$t('modelPublish.desc')}}</div>
          <div class="mt20">
            <el-input rows="4" type="textarea"
              v-model="modelPublishForm.modelIntroduce"
              :placeholder="$t('modelPublish.desc_placeholder')">
            </el-input>
          </div>
        </div>
        <!-- 描述 end -->
        <div class="button flex-c-c mt40">
          <div class="cancel" @click="uploadModelVisible = false">{{$t('common.cancel')}}</div>
          <div class="comfirm" @click="handleSubimtModel">{{$t('common.submit')}}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 上传模型弹框 end-->

    <!-- 注册弹框 -->
    <el-dialog :modal-append-to-body="false" :visible.sync="registerVisible" class="register-dialog" width="460px">
      <div slot="title" class="dialog-title">
        <div class="f30 fwb mb10">注册你的章鱼核账号</div>
        <div @click="goLogin" class="danger-color f16 flex-c">
          已有账号？前往登录
          <span class="plr4 radius20 d32-bg ml10 f12">
            <i class="el-icon-arrow-right c_fff"></i>
          </span>
        </div>
      </div>
      <div class="pd20">
        <div style="width: 195px" class="flex-c radius4 pd5 f5-bg">
          <div class="regist" :style="{ background: isPhoneRegist ? '#fff' : '' }" @click="setPhoneOrEmailRegist(true)">
            手机注册
          </div>
          <div class="regist ml10" :style="{ background: isPhoneRegist ? '' : '#fff' }" @click="setPhoneOrEmailRegist(false)">
            邮箱注册
          </div>
        </div>
        <div v-if="isPhoneRegist">
          <el-input class="mt20" type="text" v-model="registerInfo.phone" placeholder="输入手机号">
            <template slot="prepend"><div style="margin-right: -20px">+86</div></template>
          </el-input>
          <el-input class="mt10" v-model="registerInfo.code" placeholder="请输入验证码">
            <template slot="prepend">
              <div style="margin-right: -14px">
                <i class="el-icon-s-grid f20"></i>
              </div>
            </template>
            <template slot="append">
              <div class="send-captcha" @click="getCaptcha()">获取验证码</div>
            </template>
          </el-input>
        </div>
        <div v-else>
          <el-input class="mt20" type="text" v-model="registerInfo.email" placeholder="输入邮箱">
            <template slot="prepend"><i class="el-icon-user f20"></i></template>
          </el-input>
          <el-input class="mt10" v-model="registerInfo.passWord" placeholder="输入密码">
            <template slot="prepend"><i class="el-icon-lock f20"></i></template>
            <template slot="append"> <i class="el-icon-view f20"></i></template>
          </el-input>
        </div>
        <div class="button flex-c-b mt40">
          <div class="loginComfrim" style="width: 40%; border-radius: 10px">上一步</div>
          <div class="comfirm ml20" style="width: 60%; border-radius: 10px">注册</div>
        </div>
      </div>
    </el-dialog>
    <!-- 注册弹框 end-->

    <!-- 登录弹框 -->
    <el-dialog
      :modal-append-to-body="false"
      :visible.sync="loginVisible"
      :before-close="loginClose"
      append-to-body
      :close-on-click-modal="false"
      @open="openLoginDialog"
      class="login-dialog"
      width="500px"
    >
      <div slot="title" class="dialog-title">
        <el-image @click="goScanCode(1)" class="lognTab" :src="require('@/assets/images/login/qrcode.png')"></el-image>
        <!-- 登录你的章鱼核账号 -->
        <div class="f30 fwb">{{ $t("login.title") }}</div>
        <div v-if="false" @click="goRegist" class="danger-color f16 flex-c">
          <!--  没有账号？免费注册  -->
          {{ $t("login.myzhmfzc") }}
          <span class="plr4 radius20 d32-bg ml10 f12">
            <i class="el-icon-arrow-right c_fff"></i>
          </span>
        </div>
      </div>
      <div style="padding: 20px 30px">
        <div style="width: 400px" class="flex-c radius4 pd5 f5-bg">
          <div class="regist" :style="{ background: isPhoneLogin == 1 ? '#fff' : '' }" @click="setPhoneOrEmailLogin(1)">
            <!-- 手机验证码登录 -->
            {{ $t("login.sjyzmdl") }}
          </div>
          <div class="regist ml10" :style="{ background: isPhoneLogin == 2 ? '#fff' : '' }" @click="setPhoneOrEmailLogin(2)">
            <!-- 邮箱验证码登录 -->
            {{ $t("login.yxyzmdl") }}
          </div>
          <div class="regist ml10" :style="{ background: isPhoneLogin == 3 ? '#fff' : '' }" @click="setPhoneOrEmailLogin(3)">
            <!-- 账号密码登录 -->
            {{ $t("login.zhmmdl") }}
          </div>
        </div>
        <!-- 手机验证码登录 -->
        <div v-if="isPhoneLogin == 1">
          <el-input class="mt20" type="text" v-model="loginInfo.phone" :placeholder="$t('login.qsrsjh')">
            <template slot="prepend">
              <div style="margin-right: -20px">+86</div>
            </template>
          </el-input>
          <el-input class="mt10" v-model="loginInfo.code" :placeholder="$t('login.qsryzm')">
            <template slot="prepend">
              <div style="margin-right: -14px">
                <i class="el-icon-s-grid f20"></i>
              </div>
            </template>
            <template slot="append">
              <div v-if="!timerLoading && timerNum == 60" class="send-captcha" @click="getCaptcha">
                {{ $t("login.hqyzm") }}
              </div>
              <div v-else-if="timerLoading">{{ $t("login.fsz") }}</div>
              <div v-else-if="!timerLoading && timerNum != 0">
                {{ timerNum }}{{ $t("login.khq") }}
              </div>
            </template>
          </el-input>
          <!-- <el-radio v-model="isAutoLogin">下次自动登录</el-radio> -->
        </div>
        <!-- 邮箱验证码登录 -->
        <div v-else-if="isPhoneLogin == 2">
          <el-input class="mt20" type="text" v-model="loginInfo.phone" :placeholder="$t('login.qsryx')">
            <template slot="prepend">
              <div style="margin-right: -14px">
                <i class="el-icon-message" style="font-size: 18px;"></i>
              </div>
            </template>
          </el-input>
          <el-input class="mt10" v-model="loginInfo.code" :placeholder="$t('login.qsryzm')">
            <template slot="prepend">
              <div style="margin-right: -14px">
                <i class="el-icon-s-grid f20"></i>
              </div>
            </template>
            <template slot="append">
              <div v-if="!timerLoading && timerNum == 60" class="send-captcha" @click="getCaptcha">
                {{ $t("login.hqyzm") }}
              </div>
              <div v-else-if="timerLoading">{{ $t("login.fsz") }}</div>
              <div v-else-if="!timerLoading && timerNum != 0">
                {{ timerNum }}{{ $t("login.khq") }}
              </div>
            </template>
          </el-input>
          <!-- <el-radio v-model="isAutoLogin">下次自动登录</el-radio> -->
        </div>
        <!-- 账号密码登录 -->
        <div v-else>
          <el-input class="mt20" type="text" v-model="loginInfo.phone" :placeholder="$t('login.srsjhOryx')">
            <template slot="prepend"><i class="el-icon-user f20"></i></template>
          </el-input>
          <el-input class="mt10" v-model="loginInfo.passWord" show-password :placeholder="$t('login.srndmm')">
            <template slot="prepend"><i class="el-icon-lock f20"></i></template>
            <!-- 忘记密码? -->
            <template slot="append"> <span @click="handleWangJi" style="cursor: pointer;">{{$t('login.wjmm')}}</span></template>
          </el-input>
        </div>

        <div v-if="false" class="mt10 flex-c">
          <div class="check" :class="nextLogin ? 'on' : ''" @click="nextLogin = !nextLogin">
            <i v-if="nextLogin" class="el-icon-check"></i>
          </div>
          <!-- 下次自动登录 -->
          <span class="ml5 c_666" @click="handleZiDongLogin">{{$t('login.xczddl')}}</span>
        </div>
        <div class="button flex-c-c mt30">
          <!-- 登录 -->
          <div class="comfirm" style="width: 100%; border-radius: 10px" @click="handleLogin">{{$t('login.loginText')}}</div>
        </div>
        <div class="mt10 flex-c mb20">
          <div class="radius30 check" :class="agreeWith ? 'on' : ''" @click="agreeWith = !agreeWith">
            <i v-if="agreeWith" class="el-icon-check"></i>
          </div>
          <div class="ml5 c_666" @click="agreeWith = !agreeWith" style="flex: 1;">
            <!-- 我已阅读并同意章鱼核 -->
            {{$t('login.agreementText01')}}
            <span class="danger-color cursor"
                  @click.stop="toTapUrl('/agreement', {type: 1})">
                  <!-- 《用户协议》 -->
                  {{$t('login.agreementText02')}}
            </span>
            {{$t('login.agreementText03')}}<span class="danger-color cursor"
                    @click.stop="toTapUrl('/agreement', {type: 2})">
                    <!-- 《隐私政策》 -->
                    {{$t('login.agreementText04')}}
            </span>
          </div>
        </div>
        <div class="mt60">
          <!-- 其他登录方式 -->
          <el-divider class="other-login">{{$t('login.otherLogin')}}</el-divider>
        </div>
        <div v-if="lang == 'cn'" class="button flex-c-c mt30">
          <div class="loginComfrim" @click="goScanCode(2)" style="width: 33%">
            <el-image style="width: 23px; height: 18px;" class="mr10 ml10" :src="require('@/assets/images/login/wx.png')"></el-image>
            <!-- 微信登录 -->
            <span style="flex: 1">{{$t('login.wxLogin')}}</span>
          </div>
          <div class="loginComfrim ml20" @click="openBindView" style="width: 33%">
            <el-image style="width: 15px; height: 18px" class="mr10 ml10" :src="require('@/assets/images/login/qq.png')"></el-image>
            <!-- QQ登录 -->
            <span style="flex: 1">{{$t('login.qqLogin')}}</span>
          </div>
          <div class="loginComfrim ml20" @click="openWeiBoView" style="width: 33%">
            <el-image style="width: 23px; height: 21px" class="mr10 ml10" :src="require('@/assets/images/login/xinlangweibo.png')"></el-image>
            <!-- 微博登录 -->
            <span style="flex: 1">{{$t('login.weiboLogin')}}</span>
          </div>
        </div>
        <div v-else class="button flex-c-c mt30">
          <el-button type="primary" @click="onFBSignSuccess" :loading="FacebookLoading">Facebook Login</el-button>
          <el-button type="primary" class="ml30" @click="loginGoogle.requestCode();" :loading="GoogleLoading">Sign in with Google</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 登录弹框 end-->

    <!-- 扫码登录 -->
    <el-dialog
      :modal-append-to-body="false"
      :visible.sync="scanCodeVisible"
      :show-close="false"
      :before-close="() => {loginClose(); scanCodeVisible = false;}"
      class="login-dialog"
      width="500px"
    >
      <div slot="title" class="dialog-title">
        <el-image @click="scanCodeVisible = false; loginVisible = true;"
          class="lognTab" :src="require('@/assets/images/login/tabpc.png')">
        </el-image>
        <!-- 扫码登录 -->
        <div class="f30 fwb flex-c-c">{{$t('header.smdl')}}</div>
      </div>
      <div class="pd20 t_center">
        <div class="flex-c-c " :style="`font-size: ${lang == 'cn' ? '20': '18'}px;`">
          <!-- 请使用{{ scanCodeType == 1 ? "章鱼核移动端" : "微信" }}扫描二维码 -->
          {{$t('header.qsy')}}{{ scanCodeType == 1 ? $t('common.zyhydd') : $t('common.weChat') }}{{$t('header.smewm')}}
        </div>
        <!-- 二维码 -->
        <div v-show="scanCodeType == 1" class="qr-code">
          <!-- App扫码登录二维码 -->
          <el-image :src="qrCode"></el-image>
          <!-- App是否扫码二维码 -->
          <div v-show="isScanQrCode" class="qr-code-shade flex-c-c">
            <div>
              <img
                class="success-img"
                src="@/assets/images/pay/success.png"
                alt=""
              />
              <div class="text mt10">{{ $t("login.smcg") }}</div>
              <div class="text">{{ $t("login.qzsjsqr") }}</div>
            </div>
          </div>
        </div>
        <!-- 微信登录二维码 -->
        <div v-show="scanCodeType == 2" id="wxLogin_container"></div>
        <!-- 自动登录 -->
        <div v-show="scanCodeType != 2 && false" class="mt10 flex-c-c mb20">
          <div
            class="radius30 check"
            :class="autoLogin ? 'on' : ''"
            @click="autoLogin = !autoLogin"
            style="width: 20px; height: 20px; font-size: 16px;">
            <i v-if="autoLogin" class="el-icon-check"></i>
          </div>
          <span class="ml5 c_666" @click="handleAutoLogin30" style="font-size: 20px;">30天内自动登录</span>
        </div>
      </div>
    </el-dialog>
    <!-- 扫码登录 end-->

    <!-- 模型版权声明列表 start -->
    <el-dialog
      :title="$t('modelPublish.bqsm')"
      :modal-append-to-body="false"
      :visible.sync="showCopyrightList"
      width="40%"
    >
      <div class="copyright-list">
        <template v-for="(item, index) in copyrightList">
          <div :key="index" class="copyright-item flex-c-b">
            <el-radio v-model="modelCopyrightId" :label="item.id">{{item.name}}</el-radio>
            <div @click="handleViewCopyright(item.value)" style="cursor: pointer;">{{$t('modelPublish.ck')}}</div>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button  size="small" @click="showCopyrightList = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" size="small" class="confirm-copyright" @click="handleConfirmCopyrig">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
    <!-- 模型版权声明列表 end -->

    <!-- 查看版权声明 -->
    <el-dialog
      :title="$t('modelPublish.bqsm')"
      :modal-append-to-body="false"
      :visible.sync="showCopyrightDetial"
      width="40%"
    >
      <div class="copyright-detail" v-html="copyrightDetial" style="padding: 30px;"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" class="confirm-copyright" @click="showCopyrightDetial = false">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
    <!-- 查看版权声明 end -->

    <!-- 绑定手机 S -->
    <el-dialog :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="bindMobileVisible" class="register-dialog" width="460px">
      <div slot="title" class="dialog-title">
        <div class="f30 fwb mb10">{{$t('header.bdsj')}}</div>
      </div>
      <div class="pd20">
        <div>
          <el-input class="mt20" type="text" v-model="bindMobileForm.phone" :placeholder="$t('login.qsrsjh')">
            <!-- <template slot="prepend"><div style="margin-right: -20px">+86</div></template> -->
          </el-input>
          <el-input class="mt10" v-model="bindMobileForm.code" :placeholder="$t('login.qsryzm')">
            <template slot="prepend">
              <div style="margin-right: -14px">
                <i class="el-icon-s-grid f20"></i>
              </div>
            </template>
            <template slot="append">
              <div v-if="!timerLoading && timerNum == 60" class="send-captcha" @click="getCaptcha('bindMobile')">
                {{ $t("login.hqyzm") }}
              </div>
              <div v-else-if="timerLoading">{{ $t("login.fsz") }}</div>
              <div v-else-if="!timerLoading && timerNum != 0">
                {{ timerNum }}{{ $t("login.khq") }}
              </div>
            </template>
          </el-input>
        </div>
        <div class="button flex-c-b mt40">
          <div class="loginComfrim" style="width: 40%; border-radius: 10px" @click="bindMobileVisible = false">{{$t('common.cancel')}}</div>
          <div class="comfirm ml20" style="width: 60%; border-radius: 10px" @click="handleLoginByOauthBindMobile">{{$t('header.bd')}}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 绑定手机 E -->
  </div>
</template>

<script>
import utils from '@/utils/utils';
import UploadImg from "@/components/UploadImg.vue";
import UploadFile from "@/components/UploadFile.vue";
import axios from 'axios' // 引入axios
import Cookies from 'js-cookie'
import * as Base64 from 'js-base64'
import { AES } from 'crypto-js'


import { mapState, mapActions } from "vuex";
export default {
  name: "headerView",
  components: {
    UploadImg,
    UploadFile,
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      defaultAvatar: (state) => state.defaultAvatar,
      userInfo: (state) => state.userInfoModule.userInfo,
      searchText: (state) => state.search.searchText,
      loginStatus: (state) => state.loginStatus,
      carNum: (state) => state.carNum,
      modelClass: (state) => state.dict.modelClass, // 模型分类
      showUploadModel: (state) => state.showUploadModel, // 显示上传模型弹窗
    }),

    // 格式化文件大小
    filesize() {
      return val => {
        return utils.formatFileSize(val);
      }
    },
  },
  watch: {
    $route(to, form) {
      // 处理打开多个标签页，在其中一个退出后回到另外的标签页操作时清除登录信息
      let token = Cookies.get('token');
      if (!token) {
        this.setToken(null);
        this.setSecretKey(null);
        this.setUserInfo(null);
      }

      // 离开工作台板块时关闭socket链接
      if (to.path.indexOf('workbenches') == -1) {
        this.initSocket(false);
      } else {
        if (!!this.token){
          this.initSocket(true);
        }
      }
    },
    loginStatus(newV, oldV) {
      console.log("登录弹窗：", newV);
      this.loginVisible = newV;
    },

    showUploadModel(val) {
      if (val) {
        this.openUploadModel();
      }
    },

    uploadModelVisible(val) {
      if (!val) {
        this.setShowUploadModel(val);
      }
    },
  },
  data() {
    return {
      select: 1, //1:支付宝，2：微信支付
      isPhoneRegist: true, // true 手机注册 false 邮箱注册
      isPhoneLogin: 1, // 1 验证码登录， 2 邮箱登录， 3 账号密码登录
      // 路由
      routes: [
        // 模型库
        { path: "/modelLib", name: this.$t("header.modelBase"),field:[], },
        // 模型专栏
        { path: "/modelColumn", name: this.$t("header.modelColumn"),field:['/columnDetail'], },
        // 产品库
        { path: "/productLibrary", name: this.$t("header.productLibrary"),'field':['/productDetail','/shop'] },
      ],
      registerVisible: false, //注册弹框
      registerInfo: {
        // 注册信息
        phone: undefined,
        code: undefined,
        email: undefined,
        passWord: undefined,
      },
      loginVisible: false, //登录弹框
      loginInfo: {
        // 登录信息
        phone: undefined,
        code: undefined,
        passWord: undefined,
      },
      isAutoLogin: false, //是否自动登录
      isVip: false, //是否是会员

      // 模型发布form表单
      modelPublishForm: {
        modelSource: 1, // 模型来源
        modelCopyrightId: 0, // 版权声明
        modelChargeStatu: 1, // 模型收费状态: zyy_model_charge 0-收费 1-免费
        modelPrice: "", // 章鱼币价格
        modelProof: [], // 模型证明
        modelName: "", // 模型名称
        modelClass: "", // 模型类别
        modelFiles: [], // 模型文件
        modelPrivacy: 0, // 模型隐私: zyy_model_privacy 0-公用 1-私用
        modelDisplay: [], // 模型展示图
        modelIntroduce: '', // 模型介绍
      },
      modelCopyrightId: 0, // 选择的版权声明id
      modelCopyrightName: "", // 选择的版权声明名称
      modelProofList: [], // 模型证明列表
      modelFilesList: [], // 模型文件列表
      isUploadModelFilesErr: false, // 上传模型文件报错，控制删除文件按钮显示
      modelDisplayList: [], // 模型图集/模型展示图

      lang: "cn", //1：简体中文,2英文
      
      uploadModelVisible: false, //上传模型弹框
      uploadModelInfo: {
        // 上传模型信息
        price: undefined, //价格
        name: undefined, //模型名称
        type: undefined, //模型类别
        memo: undefined, //描述
      },
      imageUrl: undefined,
      nextLogin: false, //下次自动登录
      agreeWith: false, //是否同意协议
      autoLogin: false, //30天自动登录
      registCodeText: "获取验证码",
      loginCodeText: "获取验证码",
      //扫码登录
      scanCodeVisible: false, //扫码弹窗
      scanCodeType: 1, //1移动端APP登录 2微信登录

      timer: undefined, // 计时器实例
      timerNum: 60, // 计时器倒计时
      timerLoading: false, // 计时器初始化中

      search: "", // 搜索文字
      qrCode: "", // 二维码登录的二维码
      isScanQrCode: false, // 是否扫码

      copyrightList: [], // 模型版权声明列表
      showCopyrightList: false, // 显示模型版权声明列表
      showCopyrightDetial: false, // 显示模型版权声明详情
      copyrightDetial: '', // 模型版权声明详情

      bindMobileVisible: false, // 绑定手机号可见性
      bindMobileForm: {
        phone: '',
        code: '',
        type: '',
        unionid: '',
      },
      searchSelect: 0,

      uploadFileParamData: {},
      ossUploadUrl: '',
      acceptFile: '.stl, .obj',

      FacebookLoading: false,
      GoogleLoading: false,
      loginGoogle: null,
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang") || "cn";
    this.nextLogin = localStorage.getItem('nextLogin') == 'true' || false;
    // let _token = localStorage.getItem('token') || '';
    // let _secretKey = localStorage.getItem('secretKey') || '';

    localStorage.setItem("lang", this.lang);
    if (this.lang == 'en') {
      let gapiTimer = setInterval(() => {
        if (!!window.gapi) {
          clearInterval(gapiTimer);
          gapi.load('auth2', () =>{ // 加载谷歌API
            gapi.auth2.init();
          });
        }
      }, 1000);

      // gapi.load('auth2', () =>{ // 加载谷歌API
      //   gapi.auth2.init();
      // });
    }
    if (!this.token){
      let _token = Cookies.get('token') || '';
      let _secretKey = Cookies.get('secretKey') || '';
      this.setToken(_token);
      this.setSecretKey(_secretKey);
    }

    if (!!this.token) this.getUserInfo();
    // this.getQrCode();
    //判断是否有
    this.setCarNum();

    let code = this.utils.getParam("code");
    let state = this.utils.getParam("state");
    if (!!code && state == 'wechatLogin') {
      this.getAccessToken(code);
    }
    if (!!code && state == 'wechatBind') {
      this.$router.push({
        path: '/user/setAccount',
        query: {
          code: code,
          bindThreeType: 1, // 1 微信，2 qq，3 微博
          state: state,
        }
      })
    }

    if(state == 'qqLogin') {
      this.qqGetOpenId();
    }
    if (state == 'qqBind') {
      this.$router.push({
        path: '/user/setAccount',
        query: {
          bindThreeType: 2, // 1 微信，2 qq，3 微博
          state: state,
        }
      })
    }

    if(!!code && state == 'weiboLogin') {
      this.getWeiboAccessToken(code);
    }
    if (!!code && state == 'weiboBind') {
      this.$router.push({
        path: '/user/setAccount',
        query: {
          code: code,
          bindThreeType: 3, // 1 微信，2 qq，3 微博
          state: state,
        }
      })
    }

    if (!!this.token){
      this.getCopyrightList();
    }
  },
  methods: {
    ...mapActions({
      setToken: "setToken",
      setSecretKey: "setSecretKey",
      setUserInfo: "userInfoModule/setUserInfo",
      setSearchText: "search/setSearchText",
      setLoginStatus: "setLoginStatus",
      setCarNum: "setCarNum",
      initSocket: "webSocketState/initSocket", // 初始化 websocket
      setAgreement: 'setAgreement',
      setShowUploadModel: 'setShowUploadModel',
      deleteFiles: 'deleteFiles',
    }),

    //跳转路由
    toModel(path) {
      if (!this.token && path !== "/") {
        this.setLoginStatus(true);
        return;
      }
      if (this.$route.path !== path)
        this.$router.push({
          path,
        });
    },
    // 没有账号？免费注册
    goRegist() {
      this.loginVisible = false;
      this.registerVisible = true;
    },
    // 已有账号？去登录
    goLogin() {
      this.loginVisible = true;
      this.registerVisible = false;
    },
    // 邮箱or手机注册
    setPhoneOrEmailRegist(val) {
      this.isPhoneRegist = val;
    },
    // 邮箱注册
    setPhoneOrEmailLogin(val) {
      this.loginInfo = {
        // 登录信息
        phone: undefined,
        code: undefined,
        passWord: undefined,
      };
      this.isPhoneLogin = val;
    },
    //跳转购物车
    toShoppingCart() {
      if (!this.token) {
        this.setLoginStatus(true);
        return;
      }
      if (this.$route.path !== "/shoppingCart") {
        this.$router.push({ path: "/shoppingCart" });
      }else{
        location.reload();
      }
    },
    // 设置语言
    setLanguage(type) {
      console.log(type);
      localStorage.setItem("lang", type);
      location.reload();
      return;
      this.$i18n.locale = type;
      this.lang = type;
    },

    // 上传证明
    handleModelProofSuccess(res, file) {
      this.modelProofList.push(res.data);
      this.modelPublishForm.modelProof = this.modelProofList.map(
        (item) => item["id"]
      );
    },
    // 删除证明
    delModelProofImg(index) {
      let delTargetImg = [this.modelProofList[index].path];
      this.deleteFiles(delTargetImg);
      this.modelProofList.splice(index, 1);
      this.modelPublishForm.modelProof = this.modelProofList.map(
        (item) => item["id"]
      );
    },

    // 模型文件上传前
    modelFileBeforeUpload(file) {
      let isLt2M = file.size / 1024 / 1024 < 500;
      let ext = file.name.substring(file.name.lastIndexOf('.'));
      let isFileType = this.acceptFile.includes(ext);

      if (!isLt2M) {
        // this.$message.error(this.$t('modelPublish.file_tips01'));
        this.utils.reminderMsg(this.$t('modelPublish.file_tips01'), 1);
      } else if (!isFileType) {
        // this.$message.error(this.$t('modelPublish.file_type_err'));
        this.utils.reminderMsg(this.$t('modelPublish.file_type_err'), 1);
      } else {
        this.modelFilesList = this.modelFilesList.concat([{
          fileId: 0,
          pathThumb: '',
          fileName: file.name,
          modelFileProgress: 0,
          percent: 0,
          size: file.size,
          fileUid: file.uid,
        }]);
      }
      return isLt2M && isFileType;
    },

    // 模型文件上传中
    modelFileUploadProgress(res, file, index) {
      this.modelFilesList.forEach(item => {
        if (item.fileUid == file.uid) {
          item.modelFileProgress = res.loaded;

          let percent = (res.loaded / res.total) * 100 || 0
          item.percent = percent;
        }
      });
    },

    // 上传模型文件成功
    handleModelFileSuccess(res, file) {
      // this.modelFilesList.forEach(item => {
      //   if (item.fileUid == file.uid) {
      //     item.fileId = res.data.id;
      //   }
      // });

      this.modelUpload(res.data, file);
    },

    // 记录oss上传成功后的文件信息
    modelUpload(url, file, baseFileId) {
      // let fileMd5 = this.$md5(this.uploadFileParamData.key);
      let fileMd5 = '--';
      let params = {
        path: url,
        md5: fileMd5.toString(),
        fileSize: file.size,
      };
      // const key = 'QISUI9WLNGZPKQCF';
      // const iv = 'SBSAI9W8NGYPKQCV';
      // const ciphertext = AES.encrypt(url, key, {iv: iv}).toString()
      // console.log('ciphertext>>>>>>>>', Base64.encode(ciphertext));
      // setTimeout(() => {
      //   const bytes = AES.decrypt('ucq9TCEvwP3+/c4dt220rVYJiG8c+AFKLr/VebBlem9CK9+imO5RisohH+76fHQ1ttyiCvg1tbjqMadssPH41g==', key, {iv: iv})
      //   const plaintext = bytes.toString(this.$CryptoJS.enc.Utf8)
      //   console.log('plaintext>>>>>>>>>>>>>>>>>', plaintext)
      // });
      if (!!baseFileId) params['baseFileId'] = baseFileId;
      this.http(this.api.modelUpload, params, 'get').then(_res => {
        if (_res.code == 200) {
          this.modelFilesList.forEach(item => {
            if (item.fileUid == file.uid) {
              if (!baseFileId) item.fileId = _res.data;
              item.filePath = url
            }
          });

          // if (!baseFileId) {
          //   this.uploadModelOptimize(url, _res.data, (res) => {
          //     let resp = res.data;
          //     if (resp.code == 200) {
          //       // this.modelUpload(resp.data.path, file, _res.data);
          //     } else {
          //       this.$message.error(res.msg);
          //     }
          //   });
          // }
        } else {
          this.$message.error(_res.msg);
        }
      });
    },

    // 上传模型文件失败
    triggerUploadError(res, fileIndex) {
      this.isUploadModelFilesErr = true;
    },

    // 删除模型文件
    delModelFile(index, item) {
      this.modelFilesList.splice(index, 1);
      // this.modelPublishForm.modelFiles = this.modelFilesList.map((item) => ({'fileId': item['fileId'], 'pathThumb': item['pathThumb']}));
      this.isUploadModelFilesErr = false;
      let targetPaths = [item.filePath];
      if (!!item.picPath) targetPaths.push(item.picPath);
      this.deleteFiles(targetPaths);
    },

    // 上传模型文件缩略图
    handleModelFilesImgSuccess(res, item) {
      item['pathThumb'] = res.data.url;
      item['picPath'] = res.data.path;
      item['thumbPicId'] = res.data.id;
    },

    // 删除模型文件缩略图
    delModelFilesImg(index) {
      this.deleteFiles([this.modelFilesList[index].picPath]);
      this.modelFilesList[index]['pathThumb'] = '';
      this.modelFilesList[index]['picPath'] = '';
      this.modelFilesList[index]['thumbPicId'] = '';
    },

    // 上传模型图集/模型展示图
    handleModelDisplayList(res, file) {
      this.modelDisplayList.push(res.data);
    },

    // 删除模型图集/模型展示图
    delModelDisplay(index) {
      let delTargetImg = [this.modelDisplayList[index].path];
      this.deleteFiles(delTargetImg);
      this.modelDisplayList.splice(index, 1);
    },

    // 提交
    handleSubimtModel() {
      let pLanguage = localStorage.getItem('lang');
      let modelPublishForm = JSON.parse(JSON.stringify(this.modelPublishForm))

      if (modelPublishForm.modelSource == 0) {
        // 请选择模型来源
        // this.$message.error(this.$t('modelPublish.qxzmxly'));
        this.utils.reminderMsg(this.$t('modelPublish.qxzmxly'), 1);
        return;
      }
      if (modelPublishForm.modelCopyrightId == 0) {
        // 请选择只是共享协议
        // this.$message.error(this.$t("modelPublish.xzgxxy"));
        this.utils.reminderMsg(this.$t('modelPublish.xzgxxy'), 1);
        return;
      }
      if (modelPublishForm.modelSource == 1) {
        if (modelPublishForm.modelChargeStatu == 0 && !modelPublishForm.modelPrice) {
          // 请输入章鱼币价格
          // this.$message.error(this.$t('modelPublish.srzybjg'));
          this.utils.reminderMsg(this.$t('modelPublish.srzybjg'), 1);
          return;
        }

        if (!this.modelProofList.length) {
          // 请上传模型证明
          // this.$message.error(this.$t('modelPublish.qscmxzm'));
          this.utils.reminderMsg(this.$t('modelPublish.qscmxzm'), 1);
          return;
        }
      }

      if (this.modelFilesList.length == 0) {
          // 请上传模型文件
        // this.$message.error(this.$t('modelPublish.qscmxwj'));
        this.utils.reminderMsg(this.$t('modelPublish.qscmxwj'), 1);
        return;
      }

      if (this.modelFilesList.filter((item) => !!item.fileId).length != this.modelFilesList.length) {
          // 请上传模型文件
        // this.$message.error(this.$t('modelPublish.qscmxwj'));
        this.utils.reminderMsg(this.$t('modelPublish.qscmxwj'), 1);
        return;
      }

      let pathThumbLength = this.modelFilesList.filter((item) => item.pathThumb).length
      if (pathThumbLength != this.modelFilesList.length) {
          // 请上传模型缩略图
        // this.$message.error(this.$t('modelPublish.qscmxwjslt'));
        this.utils.reminderMsg(this.$t('modelPublish.qscmxwjslt'), 1);
        return;
      }
      if (!modelPublishForm.modelName) {
          // 请输入60个字以内的名称
        // this.$message.error(this.$t('modelPublish.mxmc_placeholder'));
        this.utils.reminderMsg(this.$t('modelPublish.mxmc_placeholder'), 1);
        return;
      }
      if (!modelPublishForm.modelClass) {
          // 请选择模型类别
        // this.$message.error(this.$t('modelPublish.mxlb_placeholder'));
        this.utils.reminderMsg(this.$t('modelPublish.mxlb_placeholder'), 1);
        return;
      }
      if (!this.modelDisplayList.length) {
          // 请上传图集
        // this.$message.error(this.$t('modelPublish.qsctj'));
        this.utils.reminderMsg(this.$t('modelPublish.qsctj'), 1);
        return;
      }
      let params = {
        ...modelPublishForm,
        modelProof: this.modelProofList.map((item) => item['id']),
        modelFiles: this.modelFilesList.map((item) => ({'fileId': item['fileId'], 'pathThumb': item['pathThumb'], thumbPicId: item['thumbPicId']})),
        modelDisplay: this.modelDisplayList.map((item) => item['id']),
        category: pLanguage == 'en' ? 1 : 0,
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t('common.zztj'),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      this.modelFilesList.forEach(item => {
        if (!!item.filePath) {
          this.uploadModelOptimize(item.filePath, item.fileId, (res) => {
            let resp = res.data;
            if (resp.code == 200) {
              // this.modelUpload(resp.data.path, file, _res.data);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
      setTimeout(() => {
        this.http(this.api.addModel, params, 'post').then(res => {
          loading.close();
          if (res.code == 200) {
            // this.$message.success(this.$t('common.submit_success'));
            this.utils.reminderMsg(this.$t('common.submit_success'), 0);
            this.resetModelPublish();
            this.uploadModelVisible = false;
            this.modelCopyrightId = 0;
            this.modelCopyrightName = '';
            // location.reload();
          } else {
            this.$message.error(res.msg);
          }
        });
      }, 800);
    },

    // 重置模型发布表单
    resetModelPublish() {
      // 模型发布form表单
      this.modelPublishForm = {
        modelSource: 1, // 模型来源
        modelCopyrightId: 0, // 版权声明
        modelChargeStatu: 1, // 模型收费状态: zyy_model_charge 0-收费 1-免费
        modelPrice: "", // 章鱼币价格
        modelProof: [], // 模型证明
        modelName: "", // 模型名称
        modelClass: "", // 模型类别
        modelFiles: [], // 模型文件
        modelPrivacy: 0, // 模型隐私: zyy_model_privacy 0-公用 1-私用
        modelDisplay: [], // 模型展示图
        modelIntroduce: '', // 模型介绍
      };
      this.modelCopyrightId = 0; // 选择的版权声明id
      this.modelCopyrightName = ""; // 选择的版权声明名称
      this.modelProofList = []; // 模型证明列表
      this.modelFilesList = []; // 模型文件列表
      this.isUploadModelFilesErr = false; // 上传模型文件报错，控制删除文件按钮显示
      this.modelDisplayList = []; // 模型图集/模型展示图
    },

    //获取验证码
    getCaptcha(type) {
      if (type != 'bindMobile') {
        if (this.isPhoneLogin == 1) {
          if (!this.loginInfo.phone) {
            // this.$message.error(this.$t("login.qsrsjh"));
            this.utils.reminderMsg(this.$t('login.qsrsjh'), 1);
            return;
          }
          if (!this.utils.isMobile(this.loginInfo.phone)) {
            this.utils.reminderMsg(this.$t('login.qsrzqyx'), 1);
            return;
          }
        } else if (this.isPhoneLogin == 2) {
          if (!this.loginInfo.phone) {
            this.utils.reminderMsg(this.$t('login.qsryx'), 1);
            return;
          }
          if (!this.utils.isEmail(this.loginInfo.phone)) {
            // this.$message.error(this.$t("login.qsrzqsjh"));
            this.utils.reminderMsg(this.$t('login.qsrzqsjh'), 1);
            return;
          }
        }
      } else {
        if (!this.bindMobileForm.phone) {
          // this.$message.error(this.$t("login.qsrsjh"));
          this.utils.reminderMsg(this.$t('login.qsrsjh'), 1);
          return;
        }
        if (!this.utils.isMobile(this.bindMobileForm.phone)) {
          // this.$message.error(this.$t("login.qsrzqsjh"));
          this.utils.reminderMsg(this.$t('login.qsrzqsjh'), 1);
          return;
        }
      }
      
      let params = {
        mobile: type != 'bindMobile' ? this.loginInfo.phone : this.bindMobileForm.phone,
        userType: 0, // 用户类型(0:前台用户 1:后台用户)
        captchaType: type != 'bindMobile' ? 0 : 1, // 验证码类型(0:登录验证码，1:绑定账号验证码)
      };
      if (!!this.bindMobileForm.type) params['oauthType'] = this.bindMobileForm.type
      if (this.isPhoneLogin == 2) params = {
        toEmail: this.loginInfo.phone,
        type: 3, // 用户类型(1:绑定邮箱，2:解除绑定, 3: 邮箱登录 )
      };
      this.timerLoading = true;
      let aipPath = this.api.sendCaptcha;
      if (this.isPhoneLogin == 2) aipPath = this.api.sendMailLoginCaptcha;
      this.http(aipPath, params, "post").then((res) => {
        this.timerLoading = false;
        if (res.code == 200) {
          this.timerNum--;
          this.timer = setInterval(() => {
            this.timerNum--;
            if (this.timerNum <= 0) {
              clearInterval(this.timer);
              this.timerNum = 60;
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //扫码登录

    //打开扫码登录弹窗
    /**
     * type 1移动端 2微信
     */
    goScanCode(type) {
      this.scanCodeType = type;
      this.loginVisible = false; //关闭登录弹窗
      this.scanCodeVisible = true; //打开扫码登录弹窗
      if (type == 1) this.getQrCode();
      if (type == 2) {
        this.$nextTick(() => {
          this.initWxLogin();
        });
      }
    },

    // 点击登录回调
    handleLogin() {
      if (!this.loginInfo.phone && this.isPhoneLogin == 1) {
        // this.$message.error(this.$t("login.qsrsjh"));
        this.utils.reminderMsg(this.$t('login.qsrsjh'), 1);
        return;
      }
      if (!this.loginInfo.phone && this.isPhoneLogin == 2) {
        // this.$message.error(this.$t("login.qsrsjh"));
        this.utils.reminderMsg(this.$t('login.qsryx'), 1);
        return;
      }
      if (!this.loginInfo.phone && this.isPhoneLogin == 3) {
        this.utils.reminderMsg(this.$t('login.qsrsjhOryx'), 1);
        return;
      }

      if (!this.utils.isEmail(this.loginInfo.phone) && this.isPhoneLogin == 2) {
        this.utils.reminderMsg(this.$t('login.qsrzqyx'), 1);
        return;
      }

      if (!this.utils.isMobile(this.loginInfo.phone) && !this.utils.isEmail(this.loginInfo.phone) && this.isPhoneLogin == 3) {
        // this.$message.error(this.$t("login.qsrzqsjh"));
        this.utils.reminderMsg(this.$t('login.qsrzqsjh'), 1);
        return;
      }
      if (!this.loginInfo.code && (this.isPhoneLogin == 1 || this.isPhoneLogin == 2)) {
        // this.$message.error(this.$t("login.qsryzm"));
        this.utils.reminderMsg(this.$t('login.qsryzm'), 1);
        return;
      }
      if (!this.loginInfo.passWord && this.isPhoneLogin == 3 ) {
        // this.$message.error(this.$t("login.qsrmm"));
        this.utils.reminderMsg(this.$t('login.qsrmm'), 1);
        return;
      }
      if (!this.agreeWith) {
        // this.$message.error(this.$t("login.tyysxy"));
        this.utils.reminderMsg(this.$t('login.tyysxy'), 1);
        return;
      }
      let params = {};
      let apiPath = "";
      if (this.isPhoneLogin == 1) {
        // 验证码登录
        params = {
          mobile: this.loginInfo.phone,
          captcha: this.loginInfo.code,
          userType: 0, // 用户类型(0:前台用户 1:后台用户)
        };
        apiPath = this.api.loginByCaptcha;
      } else if (this.isPhoneLogin == 2) {
        // 邮箱验证码登录
        params = {
          email: this.loginInfo.phone,
          captcha: this.loginInfo.code,
          userType: 0, // 用户类型(0:前台用户 1:后台用户)
        };
        apiPath = this.api.loginByCaptcha;
      } else {
        // 账号密码登录
        params = {
          username: this.loginInfo.phone,
          password: this.loginInfo.passWord,
          userType: 0, // 用户类型(0:前台用户 1:后台用户)
        };
        apiPath = this.api.loginByUsername;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      params['platform'] = 3, // 1 Android, 2 IOS, 3 PC
      this.http(apiPath, params).then((res) => {
        loading.close();
        if (res.code == 200) {
          this.setToken(`Bearer ${res.data.jwt}`);
          this.setSecretKey(res.data.secretKey);
          Cookies.set('token', `Bearer ${res.data.jwt}`);
          Cookies.set('secretKey', res.data.secretKey);

          this.loginVisible = false;
          this.getUserInfo();
          if (this.nextLogin) {
            localStorage.setItem('nextLogin', this.nextLogin);
            localStorage.setItem('token', `Bearer ${res.data.jwt}`);
            localStorage.setItem('secretKey', res.data.secretKey);
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('secretKey');
            localStorage.removeItem('nextLogin');
          }
          //刷新当前页
          location.reload();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取用户信息
    getUserInfo() {
      let params = {};
      this.http(this.api.getUserInfo, params, "get", {
        Authorization: this.token,
      }).then((res) => {
        this.setUserInfo(res.data);
      });
    },

    loginClose() {
      console.log("loginClose>>>>>>>>>>");
      this.setLoginStatus(false);
    },
    // 点击用户头像
    handleCommand(command) {
      switch (command) {
        case "userInfo":
          this.$router.push({ path: "/userInfo" });
          break;
        case "logout":
          this.handleLogout();
          break;
      }
    },

    // 退出登录
    handleLogout() {
      var that = this;
      this.$confirm(this.$t('login.qdtcm'), this.$t('common.hint'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: "warning",
      })
        .then(() => {
          that.http(that.api.logout, {}, "get").then((res) => {
            if (res.code == 200) {
              that.setToken(null);
              that.setSecretKey(null);
              that.setUserInfo(null);
              that.initSocket(false);
              Cookies.remove('token');
              Cookies.remove('secretKey');
              localStorage.removeItem('token');
              localStorage.removeItem('secretKey');
              localStorage.removeItem('nextLogin');
              location.href = '/';
            } else {
              that.setToken(null);
              that.setSecretKey(null);
              that.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    // 点击搜索
    handleSearch() {
      // if (!this.search) return;
      // this.setSearchText(this.search);
      // console.log(this.$route.path);
      // let  goodsList =  ['/productLibrary','/productLibrary/list','/productDetail','/shop'];
      // if(goodsList.indexOf(this.$route.path) == -1 && this.$route.path !='/'){
      //   this.$router.push({ name: "home", query: { } });
      // }

      // 搜索模型
      if (this.searchSelect == 0) {
        this.$router.push({
          path: '/modelLib', query: { keyword: this.search }
        })
      }
      // 搜索模型专栏
      if (this.searchSelect == 1) {
        this.$router.push({
          path: '/modelColumn', query: { keyword: this.search }
        })
      }
      // 搜索产品库
      if (this.searchSelect == 2) {
        this.$router.push({
          path: '/productLibrary/list', query: { keyword: this.search }
        })
      }
    },
    // 获取二维码
    getQrCode() {
      this.http(this.api.generateQrCode, {}, "get").then((res) => {
        if (res.code == 200) {
          this.qrCode = `data:image/png;base64,${res.data.QrCode}`;
          this.getPolling(res.data.uuid);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 轮询获取token
    getPolling(uuid) {
      let params = {
        uuid: uuid,
      };
      this.http(this.api.polling, params, "get").then((res) => {
        if (res.code == 200) {
          this.isScanQrCode = res.data.isScan;
          if (
            this.scanCodeVisible &&
            this.scanCodeType == 1 &&
            !res.data.token
          ) {
            setTimeout(() => {
              this.getPolling(uuid);
            }, 2000);
          } else if (!!res.data.token) {
            this.setToken(`Bearer ${res.data.token}`);
            this.setSecretKey(res.data.secretKey);
            Cookies.set('token', `Bearer ${res.data.token}`);
            Cookies.set('secretKey', res.data.secretKey);
            this.loginVisible = false;
            this.getUserInfo();

            if (this.autoLogin) {
              let _time = Date.now();
              let endTime = _time + (86400000 * 30);
              let timeFormat = this.utils.format(endTime, 'yyyy-mm-dd');
              localStorage.setItem('autoLoginTime', `${timeFormat}%Bearer ${res.data.token}`);
            }
            //刷新当前页
            location.href = '/';
          } else {
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //登录
    handleHeadreLogin() {
      this.setLoginStatus(true);
    },
    //点击上传模型
    openUploadModel() {
      if (!this.token) {
        this.setLoginStatus(true);
        return;
      }
      this.uploadModelVisible = true;
      this.getPolicy();
    },
    //打开开通会员弹窗
    openBuyMemberVisible() {
      if (!this.token) {
        this.setLoginStatus(true);
      }else{
        this.$router.push({path: '/userBuyVip'})
      }
      // this.openBuyMemberVisible = true;
    },

    // 初始化微信登录
    initWxLogin() {
      var obj = new WxLogin({
        self_redirect: false,
        id: "wxLogin_container",
        appid: "wx4a26b9af08ddf836",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent("https://core.octopusworks.com"),
        // redirect_uri: encodeURIComponent("http://core.octopusworks.com/callbackqq/"),
        state: "wechatLogin",
        style: "",
        href: "https://zyypc.59156.cn/css/threeWxLogin.css",
      });
    },

    // 获取微信登录的access_token
    getAccessToken(code) {
      let params = {
        'code': code,
      }
      this.http(this.api.getWXAuthToken, params, 'get').then(res => {
        if ( res.code == 200 ) {
          // 登录方式(1:QQ，2:微信，3:微博)
          if (!res.data) return;
          // let data = JSON.parse(res.data);
          // if (!!data.errcode) return;
          this.handleLoginByOauth(res.data, 2);
        } else {
          this.$message.error(res.msg);
        }
      })
    },
  
    // qq登录
    openBindView(){
      QC.Login.showPopup({
        appId: '102055403',
        redirectURI: 'http://core.octopusworks.com/callbackqq?state=qqLogin',
      })
    },

    qqGetOpenId() {
      const that = this;
      
      QC.Login.getMe(function(openId, accessToken) {
        console.log('qqGetOpenId>>openId>>>>>>>', openId)
        console.log('qqGetOpenId>>accessToken>>>>>>>', accessToken)
        let params = {
          'code': accessToken,
        }
        that.http(that.api.getQQAuthToken, params, 'get').then(res => {
          if ( res.code == 200 ) {
            // 登录方式(1:QQ，2:微信，3:微博)
            if (!res.data) return;
            // let data = JSON.parse(res.data);
            // if (!!data.errcode) return;
            that.handleLoginByOauth(res.data, 1);
          } else {
            that.$message.error(res.msg);
          }
        })
        
      });
    },

    // 微博登录
    openWeiBoView() {
      let redirect_uri = encodeURIComponent('http://core.octopusworks.com');
      window.open(`https://api.weibo.com/oauth2/authorize?client_id=3741842978&response_type=code&redirect_uri=${redirect_uri}&state=weiboLogin`)
    },

    getWeiboAccessToken(code) {
        let params = {
          code: code,
          url: 'http://core.octopusworks.com',
        }
        this.http(this.api.getWBAuthToken, params, 'get').then(res => {
          if ( res.code == 200 ) {
            // 登录方式(1:QQ，2:微信，3:微博)
            if (!res.data) return;
            // let data = JSON.parse(res.data);
            this.handleLoginByOauth(res.data, 3);
          } else {
            this.$message.error(res.msg);
          }
        })
    },

    // 三方登录
    handleLoginByOauth(unionid, type) {
      let params = {
        // code: unionid,
        type: type, // 登录方式(1:QQ，2:微信，3:微博, 4 Google, 5 Facebook)
        platform: 3, // 1 Android, 2 IOS, 3 PC
        uuid: unionid, // 用户uuid
      };
      this.http(this.api.loginByOauth, params, "post").then((res) => {
        if (res.code == 200) {
          this.setToken(`Bearer ${res.data.jwt}`);
          this.setSecretKey(res.data.secretKey);
          Cookies.set('token', `Bearer ${res.data.jwt}`);
          Cookies.set('secretKey', res.data.secretKey);
          this.loginVisible = false;
          this.getUserInfo();
          //刷新当前页
          // location.reload();
          location.href = '/';
        } else if (res.code == 500) {
          // 没有绑定手机号执行代码
          this.bindMobileForm.type = type;
          // this.bindMobileForm.unionid = unionid;
          this.bindMobileForm.uuid = unionid;
          this.bindMobileVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取模型版权声明
    getCopyrightList() {
      let lang = localStorage.getItem("lang");
      let language = "CN"; // 国际化 “en_US” 英文 “ “或”CN”为中文
      if (lang == "en") language = "en_US";

      let params = {
        'lang': language,
      };
      this.http(this.api.getCopyrightList, params, 'get').then(res => {
        if (res.code == 200) {
          this.copyrightList = res.rows;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 选择版权声明
    handleConfirmCopyrig() {
      this.modelPublishForm.modelCopyrightId = this.modelCopyrightId;
      this.copyrightList.forEach(item => {
        if (item.id == this.modelCopyrightId) this.modelCopyrightName = item.name;
      });
      this.showCopyrightList = false;
    },
    // 显示版权声明详情
    handleViewCopyright(val) {
      this.showCopyrightDetial = true;
      this.copyrightDetial = val;
    },
  
    //跳转链接
    toTapUrl(path, param){
      ///防相同路由跳转
      this.setLoginStatus(false);
      if(this.$route.path !== path){
        this.$router.push({
          path: path,
          query: param,
        })
      }else{
        if(path == '/agreement'){
          this.setAgreement(param.type);
        }
      }
    },

    handleLoginByOauthBindMobile() {
      if (!this.bindMobileForm.phone) {
        // this.$message.error(this.$t("login.qsrsjh"));
        this.utils.reminderMsg(this.$t('login.qsrsjh'), 1);
        return;
      }
      if (!this.utils.isMobile(this.bindMobileForm.phone)) {
        // this.$message.error(this.$t("login.qsrzqsjh"));
        this.utils.reminderMsg(this.$t('login.qsrzqsjh'), 1);
        return;
      }
      if (!this.bindMobileForm.code) {
        // this.$message.error(this.$t("login.qsryzm"));
        this.utils.reminderMsg(this.$t('login.qsryzm'), 1);
        return;
      }
      let params = {
        'mobile': this.bindMobileForm.phone,
        'type': this.bindMobileForm.type,
        // 'code': this.bindMobileForm.unionid,
        'uuid': this.bindMobileForm.uuid,
        'captcha': this.bindMobileForm.code,
        'captchaType': 1, // 验证码类型(1:绑定手机号验证码，2:三方注册验证码，3:修改密码验证码，4:解绑手机号验证码，5:解绑邮箱验证码，6:绑定邮箱验证码)
        platform: 3, // 1 Android, 2 IOS, 3 PC
      };
      this.http(this.api.loginByOauthBindMobile, params, 'post').then(res => {
        if (res.code == 200) {
          this.setToken(`Bearer ${res.data.jwt}`);
          this.setSecretKey(res.data.secretKey);
          Cookies.set('token', `Bearer ${res.data.jwt}`);
          Cookies.set('secretKey', res.data.secretKey);
          //刷新当前页
          location.href = location.origin
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleZiDongLogin() {
       this.nextLogin = !this.nextLogin
    },
    handleWangJi() {
      this.$router.push({path: '/changePassword'});
      this.setLoginStatus(false);
    },
    selectMenu() {
      this.search = '';
    },

    // 点击30天内登录
    handleAutoLogin30() {
      this.autoLogin = !this.autoLogin
    },

    getPolicy() {
      this.http(this.api.getPolicy, {}, 'get').then(res => {
        this.uploadFileParamData = {
          key: res.dir,
          policy: res.policy,
          OSSAccessKeyId: res.accessid,
          success_action_status: 200,
          signature: res.signature,
        }
      })
    },

    // 自定义上传模型文件
    uploadModelFile(option) {
      let params = {...this.uploadFileParamData, file: option.file};
      params.key = params.key += option.file.name;
      const formData = new FormData();
      Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
      })

      this.http(this.api.checkRemainStorageSpace, {fileSize: option.file.size}, 'get').then(res => {
        if (res.code == 200) {
          axios({
            url: 'https://pppa.oss-cn-shenzhen.aliyuncs.com',
            method: 'post',
            data: formData,
            headers: {Authorization: this.token},
            onUploadProgress: (res) => {
              option.onProgress(res, option.file);
            },
          }).then(res => {
            option.onSuccess({code: 200, data: params.key});
          }).catch(this.triggerUploadError);
        } else {
          // this.$message.error(this.$t('modelPublish.cckjbz'));
          this.utils.reminderMsg(this.$t('modelPublish.cckjbz'), 1);
          let modelFilesList = this.modelFilesList.map(v => v.uid);
          let modelFilesIndex = modelFilesList.indexOf(option.file.uid);
          this.modelFilesList.splice(modelFilesIndex, 1);
        }
      })
      
    },

    // 输入章鱼币
    inputZybCallback(value) {
      let numStr = /^[0-9]*$/;
      if (numStr.test(value)) {
        if ( value >= 0 && value < 10000) {
          this.modelPublishForm.modelPrice = value;
        } else {
          if (value < 0) this.modelPublishForm.modelPrice = '';
          this.modelPublishForm.modelPrice = value.substring(0, 4);
        }
      } else {
        this.modelPublishForm.modelPrice = value.replace(/[^0-9]/g, '');
      }
    },

    // 优化上传的模型
    uploadModelOptimize(url, baseFileId, successCallback) {
      axios({
        url: 'https://model-optimize.octopusworks.com/api/model/optimize',
        method: 'post',
        data: {
          // ossFilePath: Base64.encode(url),
          ossFilePath: url,
          baseFileId: baseFileId
        },
        headers: {
          Authorization: this.token,
        }
      }).then(res => {
        successCallback(res);
      }).catch(error => {
        console.error(error);
      });
    },

    // 上传模型弹窗关闭时删除已上传的文件
    uploadModelClose() {
      let targetPaths = [];
      this.modelFilesList.forEach(item => {
        if (!!item.filePath) targetPaths.push(item.filePath)
        if (!!item.picPath) targetPaths.push(item.picPath);
      });
      this.modelDisplayList.forEach(item => {
        targetPaths.push(item.path)
      });
      this.modelProofList.forEach(item => {
        targetPaths.push(item.path)
      });

      if (!!targetPaths.length) this.deleteFiles(targetPaths);
      this.resetModelPublish();
    },

    // Feckbock登录授权
    onFBSignSuccess(user) {
      const that = this;
      // 请求后端接口，对登录信息进行校验
      console.log('Feckbock登录授权', user);
      FB.getLoginStatus(function (response) {
        console.log('Feckbock登录状态检查', response)
        // 登录状态 - 已登录
        if (response.status === 'connected') {
          console.log('Feckbock登录状态', response)
              that.FBLogin(response.authResponse.accessToken);
        } else {
          // 登录状态 - 未登录
          FB.login(
            function (res) {
              console.log('Feckbock登录信息', res)
              if (res.status != 'unknown') that.FBLogin(res.authResponse.accessToken);
              // 获取用户资料
            },

            // 授权的各种权限
            //[facebook权限参考文档](https://developers.facebook.com/docs/permissions/reference)
            { scope: 'public_profile,email' }
          )
        }
      });
    },
    onFBSignError(error) {
      console.log(error)
    },
    FBLogin(code) {
      let params = {
        code: code,
        type: 2, // 1 app, 2 pc,
      };
      this.http(this.api.getFacebookAuthAppToken, params, 'get').then(res => {
        if (res.code == 200) {
          
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    openLoginDialog() {
      // 加载Facebook sdk和初始化
      if (!window.FB && this.lang == 'en') {
        this.FacebookLoading = true;
        window.fbAsyncInit = function () {
          FB.init({
            appId: '855596819536330', // 填入自己 app 的 id
            cookie: true,
            xfbml: true,
            version: 'v2.7' // 目前版本
          })
        };
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) {
            return
          }
          js = d.createElement(s)
          js.id = id
          js.src = 'https://connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')

        let FB_yes = setInterval(() => {
          this.FacebookLoading = !window.FB;
          if (!this.FacebookLoading) {
            clearInterval(FB_yes);
          }
        }, 1000)
      }
    
      if (this.lang == 'en' && !window.google) {
        const that = this;
        this.GoogleLoading = true;
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) {
            return
          }
          js = d.createElement(s)
          js.id = id
          js.src = 'https://accounts.google.com/gsi/client'
          js.onload = () => {
            that.loginGoogle = google.accounts.oauth2.initCodeClient({
              client_id: '484565833534-vuffak8q2s462nsl7kfeaunpoklrs593.apps.googleusercontent.com',
              scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
              ux_mode: 'popup',
              callback: (response) => {
                console.log('Google 授权成功>>>>>>>>>', response);
                let params = {
                  code: response.code,
                  type: 2, // 1 app, 2 pc,
                };
                that.http(that.api.getGoogleAccessToken, params, 'get').then(res => {
                  if (res.code == 200) {
                    // let data = JSON.parse(res.data);
                    that.handleLoginByOauth(res.data, 4);
                  } else {
                    that.$message.error(res.msg);
                  }
                });
              },
            });
          }
          fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'google-jssdk')
        let G_yes = setInterval(() => {
          this.GoogleLoading = !window.google;
          if (!this.GoogleLoading) {
            clearInterval(G_yes);
          }
        }, 1000)
      }
    },
  },
  created(){
    var params = this.$route.query;

  },
};
</script>

<style lang="less" scoped>
.out-time {
  font-size: 14px;
  font-weight: bold;
  color: #d71518;
}
.active {
  width: 166px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ff0000;
  border-radius: 1px;
  position: relative;
}
.default {
  width: 166px;
  height: 60px;
  background: #ffffff;
  border-radius: 1px;
  position: relative;
}
.active-img {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.renew {
  min-width: 50px;
  height: 24px;
  background: linear-gradient(90deg, #ff373a 0%, #ff8b60 100%);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #fffefe;
  padding: 0 5px;
}
.avatar-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.send-captcha,
.user-box {
  cursor: pointer;
}

.qr-code {
  width: 230px;
  height: 230px;
  position: relative;
  margin: 30px auto;

  .el-image {
    width: 100%;
    height: 100%;
  }

  .qr-code-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    color: #333;

    .success-img {
      background: #fff;
      border-radius: 50%;
    }
  }
}

#wxLogin_container {
  width: 230px;
  height: 300px;
  margin: 15px auto 0;

  /deep/ iframe {
    width: 100%;
    height: 100%;
  }
}

.model-source {
  /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #ffebeb;
    border-color: #ffebeb;
    box-shadow: #ffebeb -1px 0px 0px 0px;
    color: #c32d30;
  }
  /deep/ .el-radio-button__inner:hover {
    color: #c32d30;
  }
}

.model-proof-list {
  flex-wrap: wrap;

  .model-proof-item {
    width: 130px;
    height: 130px;
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    .el-image {
      width: 100%;
      height: 100%;
    }

    .del-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
      text-align: right;
      background: rgba(0, 0, 0, 0.3);
      padding: 2px 5px;

      .del-icon {
        font-size: 20px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.mode-file-list {
  .mode-file-item {
    align-items: stretch;

    .file-thumb {
      width: 80px;
      height: 80px;
      position: relative;

      .el-image {
        width: 100%;
        height: 100%;
      }

      .del-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        text-align: right;
        background: rgba(0, 0, 0, 0.3);
        padding: 2px 5px;

        .del-icon {
          font-size: 16px;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .mode-file-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;

      .file-name {
        font-size: 20px;
        color: #333;
      }

      .del-icon {
        font-size: 20px;
        cursor: pointer;
      }

      .model-file-size {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
      }
    }
  }

  .mode-file-item + .mode-file-item {
    margin-top: 20px;
  }
}

.upload-box{
  width: 100%;
  height: 100%;

  /deep/ .el-upload {
    width: 100%;
    height: 100%;
  }
}
/deep/ .UploadFile {
  width: 100%;
  height: 100%;

  .el-upload {
    width: 100%;
    height: 100%;
  }
}

.copyright-list {
  padding: 10px 30px;

  .copyright-item {
    padding: 15px 0;
    border-bottom: 1px solid #ededed;
  }
}

.confirm-copyright {
  background: #c32d30;
  border-color: #c32d30;

  &.el-button--primary:focus,
  &.el-button--primary:hover {
    background: #ce4c4e  !important;
    border-color: #ce4c4e  !important;
    color: #FFF;
  }
}

.search-view-bg {
  .el-select {
    width: 90px;

    &.en {
      width: 125px;
    }

    /deep/ .el-input__inner {
      padding-left: 0;
      text-align: center;
    }
  }

  .input::placeholder {
    color: #ccc;
  }
 }

 .header-view {
    // z-index: 9999;
 }

 .el-divider--horizontal {
  margin: 15px 0;
 }

 .check {
  width: 12px;
  height: 12px;
  font-size: 12px;
 }

 .ther-login .el-divider__text {
  font-size: 16px;
 }
</style>
